<template>
<!-- eslint-disable -->
	<div lang=EN-US link="#0563C1" vlink="#954F72" class="mainSection">

		<div class=WordSection1>

			<p class=MsoNormal align=center style='text-align:center;line-height:115%'><b><span
						style='font-size:24.0pt;line-height:115%'>Data Processing Agreement</span></b></p>

			<p class=MsoNormal style='line-height:115%'>&nbsp;</p>

			<p class=MsoNormal style='line-height:115%'>This TrafficManager Data Processing
				Agreement is made available also at <a href="https://www.trafficmanager.com/legal"
					style="font-weight: bold;" target="_blank">Legal</a>
				and is incorporated into the TrafficManager Customer Terms of Use available at <a
					href="https://www.trafficmanager.com/legal#terms-of-use/" style="font-weight: bold;"
					target="_blank">Terms of Use</a>,
				as specified in the TrafficManager Customer Terms of Use. For the Customers
				that would like to receive a signed copy of the TrafficManager Data Processing
				Agreement, they can ask it by opening a support ticket in our client area at <a
					href="https://www.trafficmanager.com/login" style="font-weight: bold;" target="_blank">Login</a>
			</p>

			<p class=MsoNormal style='line-height:115%'>No changes made to this copy are
				agreed to by TrafficManager or its affiliates.</p>

			<p class=MsoNormal style='line-height:115%'>Please note that we update the Data
				Processing Agreement as we describe in the "General Provisions" section below.
				Current Data Processing Agreement terms are available at <a href="https://www.trafficmanager.com/legal"
					style="font-weight: bold;" target="_blank">Legal</a></p>

			<p class=MsoNormal style='line-height:115%'>If you have any questions, please
				contact your TrafficManager representative. </p>

			<p class=MsoNormal style='line-height:115%'>&nbsp;</p>

			<p class=MsoNormal align=center style='text-align:center;line-height:115%'><b><span
						style='font-size:12.0pt;line-height:115%'>TrafficManager Data Processing
						Agreement</span></b></p>

			<p class=MsoNormal style='line-height:115%'>This TrafficManager Data Processing
				Agreement and its Annexes ("DPA") reflects the parties, agreement with respect
				to the Processing of Personal Data by us on behalf of you in connection with
				the TrafficManager Subscription Services referred as "Services" under the section
				1 (F) of the TrafficManager Client Agreement between you and us (also referred
				to in this DPA as the "Agreement") </p>

			<p class=MsoNormal style='line-height:115%'>This DPA is supplemental to, and
				forms an integral part of the Agreement and is effective upon its incorporation
				into the Agreement, which may be specified in the Agreement, an order or an
				executed amendment to the Agreement. In case of any conflict or inconsistency
				with the terms of the Agreement, this DPA will take precedence over the terms
				of the Agreement to the extent of such conflict or inconsistency. </p>

			<p class=MsoNormal style='line-height:115%'>The purpose of this DPA is to
				define, according to article 28 of the GDPR, the conditions under which
				TrafficManager is entitled, as a Processor and as part of the Services defined
				in the Agreement, to carry out the processing of Personal Data on behalf of,
				and on instructions from the Client, excluding the personal data processing
				activities performed by TrafficManager as a controller. The conditions into
				which TrafficManager processes, as a Controller, Personal Data relating to the
				Client (including the Client`s agents), are defined in the TrafficManager
				privacy policy: <a href="https://www.trafficmanager.com/legal#privacy-policy/" style="font-weight: bold;"
					target="_blank">Privacy Policy</a>
			</p>

			<p class=MsoNormal style='line-height:115%'>We update these terms from time to
				time. If you have an active TrafficManager subscription, we will let you know
				when we do via email (if you have subscribed to receive email notifications) or
				via in-app notification. </p>

			<p class=MsoNormal style='line-height:115%'>The term of this DPA will follow
				the term of the Agreement. Terms not otherwise defined in this DPA will have
				the meaning as set forth in the Agreement.</p>

			<p class=MsoNormal style='line-height:115%'>1. Definitions</p>

			<p class=MsoNormal style='line-height:115%'>2. Customer Responsibilities</p>

			<p class=MsoNormal style='line-height:115%'>3. TrafficManager Obligations</p>

			<p class=MsoNormal style='line-height:115%'>4. Data Subject Requests</p>

			<p class=MsoNormal style='line-height:115%'>5. Sub-Processors</p>

			<p class=MsoNormal style='line-height:115%'>6. Data Transfers</p>

			<p class=MsoNormal style='line-height:115%'>7. Additional Provisions for
				European Data</p>

			<p class=MsoNormal style='line-height:115%'>8. Additional Provisions for
				California Personal Information</p>

			<p class=MsoNormal style='line-height:115%'>9. General Provisions</p>

			<p class=MsoNormal style='line-height:115%'>10.Parties to this DPA</p>

			<br>

			<p class=MsoNormal style='line-height:115%'>Annex 1 - Details of Processing</p>

			<p class=MsoNormal style='line-height:115%'>Annex 2 - Security Measures</p>

			<p class=MsoNormal style='line-height:115%'>Annex 3 - List of Sub-Processors</p>

			<p class=MsoNormal style='line-height:115%'>Annex 4 - Standard Contractual
				Clauses</p>

			<p class=MsoNormal style='line-height:115%'>&nbsp;</p><br><br>

			<p class=MsoNormal style='line-height:115%'><b><span style='font-size:12.0pt;
line-height:115%'>1. Definitions</span></b></p>

			<p class=MsoNormal style='line-height:115%'>Expressions which begin with an
				upper-case letter and which are not defined in this DPA shall have the meaning
				as set out in the Agreement. "Data Subject", "Binding Corporate Rules",
				"Controller", "Personal Data", "Personal Data Breach", "Processing",
				"Processor", "Supervisory Authority" are interpreted as defined in the
				Regulation (EU) 2016/679 of the European Parliament and of the Council of 27
				April 2016 on the protection of natural persons with regard to the processing
				of personal data and on the free movement of such data ("General Data Protection
				Regulation" or "GDPR").</p>

			<p class=MsoNormal style='line-height:115%'>&quot;TrafficManager&quot; means
				the service provider, TRAFFIC MANAGER GROUP S.R.L. with registered address Str.
				Ionel Teodoreanu 1 nr. 1 bl. 1 ap. 1 500165 Bra&#537;ov, Romania, VAT
				RO43058081 and registration number J08/1846/2020 under the laws of Romania,
				European Union.</p>

			<p class=MsoNormal style='text-align:justify;line-height:115%'>The "Service" or
				the "Platform": the TrafficManager affiliate tracking software/technology
				provided to our clients, including all the updates, new features,
				customizations, server installation and maintenance, updates and monitoring
				developed in whole or partially by us." </p>

			<p class=MsoNormal style='line-height:115%'>"Data Protection Laws" means all
				applicable worldwide legislation relating to data protection and privacy which
				applies to the respective party in the role of Processing Personal Data in
				question under the Agreement, including without limitation European Data
				Protection Laws, the CCPA and the data protection and privacy laws of Australia
				and Singapore; in each case as amended, repealed, consolidated, or replaced
				from time to time. </p>

			<p class=MsoNormal style='line-height:115%'>"Data Subject" means the individual
				to whom Personal Data relates.</p>

			<p class=MsoNormal style='line-height:115%'>&quot;Europe&quot; means the
				European Union, the European Economic Area and/or their member states, Switzerland,
				and the United Kingdom. </p>

			<p class=MsoNormal style='line-height:115%'>"European Data" means Personal Data
				that is subject to the protection of European Data Protection Laws.</p>

			<p class=MsoNormal style='line-height:115%'>&quot;European Data Protection
				Laws&quot; means data protection laws applicable in Europe, including: (i)
				Regulation 2016/679 of the European Parliament and of the Council on the
				protection of natural persons with regard to the processing of personal data
				and on the free movement of such data (General Data Protection Regulation)
				(&quot;GDPR&quot;); (ii) Directive 2002/58/EC concerning the processing of
				personal data and the protection of privacy in the electronic communications
				sector; and (iii) applicable national implementations of (i) and (ii); or (iii)
				GDPR as it forms part of the United Kingdom domestic law by virtue of Section 3
				of the European Union (Withdrawal) Act 2018 (&quot;UK GDPR&quot;); and (iv)
				Swiss Federal Data Protection Act on 19 June 1992 and its Ordinance ("Swiss
				DPA"); in each case, as may be amended, superseded or replaced. </p>

			<p class=MsoNormal style='line-height:115%'>"Instructions" means the written,
				documented instructions issued by a Controller to a Processor, and directing
				the same to perform a specific or general action with regard to Personal Data
				(including, but not limited to, depersonalizing, blocking, deletion, making
				available).</p>

			<p class=MsoNormal style='line-height:115%'>&quot;Permitted Affiliates&quot;
				means any of your Affiliates that (i) are permitted to use the Subscription
				Services pursuant to the Agreement but have not signed their own separate
				agreement with us and are not a "Customer" as defined under the Agreement, (ii)
				qualify as a Controller of Personal Data Processed by us, and (iii) are subject
				to European Data Protection Laws.</p>

			<p class=MsoNormal style='line-height:115%'>"Personal Data" means any information
				relating to an identified or identifiable individual where such information is
				contained within Customer Data and is protected similarly as personal data,
				personal information or personally identifiable information under applicable
				Data Protection Laws.</p>

			<p class=MsoNormal style='line-height:115%'>"Personal Data Breach" means a
				breach of security leading to the accidental or unlawful destruction, loss,
				alteration, unauthorized disclosure of, or access to, Personal Data
				transmitted, stored or otherwise Processed by us and/or our Sub-Processors in
				connection with the provision of the Subscription Services. </p>

			<p class=MsoNormal style='line-height:115%'>&quot;Personal Data Breach&quot;
				will not include unsuccessful attempts or activities that do not compromise the
				security of Personal Data, including unsuccessful log-in attempts, pings, port
				scans, denial of service attacks, and other network attacks on firewalls or
				networked systems.</p>

			<p class=MsoNormal style='line-height:115%'>&quot;Privacy Shield&quot; means
				the EU-U.S. and Swiss-US Privacy Shield self-certification program operated by
				the U.S. Department of Commerce and approved by the European Commission
				pursuant to its Decision of July, 12 2016 and by the Swiss Federal Council on
				January 11, 2017 respectively; as may be amended, superseded or replaced.</p>

			<p class=MsoNormal style='line-height:115%'>&quot;Privacy Shield
				Principles&quot; means the Privacy Shield Principles (as supplemented by the
				Supplemental Principles) contained in Annex II to the European Commission
				Decision of July, 12 2016; as may be amended, superseded or replaced.</p>

			<p class=MsoNormal style='line-height:115%'>"Processing" means any operation or
				set of operations which is performed on Personal Data, encompassing the
				collection, recording, organization, structuring, storage, adaptation or
				alteration, retrieval, consultation, use, disclosure by transmission,
				dissemination or otherwise making available, alignment or combination,
				restriction or erasure of Personal Data. The terms "Process", "Processes" and
				"Processed" will be construed accordingly.</p>

			<p class=MsoNormal style='line-height:115%'>"Processor" means a natural or
				legal person, public authority, agency or other body which Processes Personal
				Data on behalf of the Controller.</p>

			<p class=MsoNormal style='line-height:115%'>"Standard Contractual Clauses"
				means the standard contractual clauses annexed to the European Commission"s
				Decision (EU) 2021/914 of 4 June 2021, in the form set out at Annex 4; as may
				be amended, superseded or replaced. </p>

			<p class=MsoNormal style='line-height:115%'>"Sub-Processor" means any Processor
				engaged by us or our Affiliates to assist in fulfilling our obligations with
				respect to the provision of the Subscription Services under the Agreement.
				Sub-Processors may include third parties or our Affiliates but will exclude any
				TrafficManager employee or consultant. </p>

			<p class=MsoNormal style='line-height:115%'>&nbsp;</p>

			<p class=MsoNormal style='line-height:115%'><b><span style='font-size:12.0pt;
line-height:115%'>2. Customer Responsibilities</span></b></p>

			<ol style='margin-top:0cm' start=1 type=a>
				<li class=MsoNormal style='line-height:115%'>Compliance with Laws. </li>
			</ol>

			<p class=MsoNormal style='margin-left:36.0pt;line-height:115%'>Within the scope
				of the Agreement and in its use of the services, you will be responsible for complying
				with all requirements that apply to it under applicable Data Protection Laws
				with respect to its Processing of Personal Data and the Instructions it issues
				to us. In particular but without prejudice to the generality of the foregoing,
				you acknowledge and agree that you will be solely responsible for: (i) the
				accuracy, quality, and legality of Customer Data and the means by which you
				acquired Personal Data; (ii) complying with all necessary transparency and
				lawfulness requirements under applicable Data Protection Laws for the
				collection and use of the Personal Data, including obtaining any necessary
				consents and authorizations (particularly for use by Customer for marketing
				purposes); (iii) ensuring you have the right to transfer, or provide access to,
				the Personal Data to us for Processing in accordance with the terms of the
				Agreement (including this DPA); (iv) ensuring that your Instructions to us
				regarding the Processing of Personal Data comply with applicable laws,
				including Data Protection Laws; and (v) complying with all laws (including Data
				Protection Laws) applicable to any emails or other content created, sent or
				managed through the Subscription Services, including those relating to
				obtaining consents (where required) to send emails, the content of the emails
				and its email deployment practices. The Customer acknowledges and agrees that
				it shall inform its end-users about the processing of their IP addresses and
				about the use of cookies in their respective privacy policies and provide an
				opt-out. You will inform us without undue delay if you are not able to comply
				with your responsibilities under this "Compliance with Laws" section or
				applicable Data Protection Laws. </p>

			<ol style='margin-top:0cm' start=2 type=a>
				<li class=MsoNormal style='line-height:115%'>Controller Instructions. </li>
			</ol>

			<p class=MsoNormal style='margin-left:36.0pt;line-height:115%'>The parties
				agree that the Agreement (including this DPA), together with your use of the
				Subscription Service in accordance with the Agreement, constitute your complete
				Instructions to us in relation to the Processing of Personal Data, so long as
				you may provide additional instructions during the subscription term that are
				consistent with the Agreement, the nature and lawful use of the Subscription
				Service.</p>

			<ol style='margin-top:0cm' start=3 type=a>
				<li class=MsoNormal style='line-height:115%'>Security. </li>
			</ol>

			<p class=MsoNormal style='margin-left:36.0pt;line-height:115%'>You are
				responsible for independently determining whether the data security provided
				for in the Subscription Service adequately meets your obligations under
				applicable Data Protection Laws. You are also responsible for your secure use
				of the Subscription Service, including protecting the security of Personal Data
				in transit to and from the Subscription Service (including to securely backup
				or encrypt any such Personal Data).</p>

			<p class=MsoNormal style='margin-left:36.0pt;line-height:115%'>&nbsp;</p>

			<p class=MsoNormal style='line-height:115%'><b><span style='font-size:12.0pt;
line-height:115%'>3. TrafficManager Obligations</span></b></p>

			<ol style='margin-top:0cm' start=1 type=a>
				<li class=MsoNormal style='line-height:115%'>Compliance with Instructions. </li>
			</ol>

			<p class=MsoNormal style='margin-left:36.0pt;line-height:115%'>We will Process
				Personal Data only on behalf of the Customer and in compliance with its
				instructions and the Clauses and for the purposes described in this DPA or as
				otherwise agreed within the scope of your lawful Instructions, except where and
				to the extent otherwise required by applicable law; if we cannot provide such
				compliance for whatever reasons, we agree to inform promptly the Customer of our
				inability to comply, in which case the Customer is entitled to suspend the
				transfer of data and/or terminate the contract. We are not responsible for
				compliance with any Data Protection Laws applicable to you or your industry
				that are not generally applicable to us.</p>

			<ol style='margin-top:0cm' start=2 type=a>
				<li class=MsoNormal style='line-height:115%'>Conflict of Laws. </li>
			</ol>

			<p class=MsoNormal style='margin-left:36.0pt;line-height:115%'>If we become
				aware that we cannot Process Personal Data in accordance with your Instructions
				due to a legal requirement under any applicable law, we will (i) promptly
				notify you of that legal requirement to the extent permitted by the applicable
				law; and (ii) where necessary, cease all Processing (other than merely storing
				and maintaining the security of the affected Personal Data) until such time as
				you issue new Instructions with which we are able to comply. If this provision
				is invoked, we will not be liable to you under the Agreement for any failure to
				perform the applicable Subscription Services until such time as you issue new
				lawful Instructions with regard to the Processing.</p>

			<ol style='margin-top:0cm' start=3 type=a>
				<li class=MsoNormal style='line-height:115%'>Security. </li>
			</ol>

			<p class=MsoNormal style='margin-left:36.0pt;line-height:115%'>We will
				implement and maintain appropriate technical and organizational measures to
				protect Personal Data from Personal Data Breaches, as described under to this
				DPA (&quot;Security Measures&quot;). Notwithstanding any provision to the
				contrary, we may modify or update the Security Measures at our discretion
				provided that such modification or update does not result in a material
				degradation in the protection offered by the Security Measures.</p>

			<ol style='margin-top:0cm' start=4 type=a>
				<li class=MsoNormal style='line-height:115%'>Confidentiality. </li>
			</ol>

			<p class=MsoNormal style='margin-left:36.0pt;line-height:115%'>We will ensure
				that any personnel whom we authorize to Process Personal Data on our behalf is
				subject to appropriate confidentiality obligations (whether a contractual or
				statutory duty) with respect to that Personal Data.</p>

			<ol style='margin-top:0cm' start=5 type=a>
				<li class=MsoNormal style='line-height:115%'>Personal Data Breaches. </li>
			</ol>

			<p class=MsoNormal style='margin-left:36.0pt;line-height:115%'>We will notify
				you without undue delay after we become aware of any Personal Data Breach and
				will provide timely information relating to the Personal Data Breach as it
				becomes known or reasonably requested by you. At your request, we will promptly
				provide you with such reasonable assistance as necessary to enable you to
				notify relevant Personal Data Breaches to competent authorities and/or affected
				Data Subjects, if you are required to do so under Data Protection Laws.</p>

			<ol style='margin-top:0cm' start=6 type=a>
				<li class=MsoNormal style='line-height:115%'>Deletion or Return of Personal
					Data. </li>
			</ol>

			<p class=MsoNormal style='margin-left:36.0pt;line-height:115%'>We will delete
				or return all Customer Data, including Personal Data (including copies thereof)
				Processed pursuant to this DPA, on termination or expiration of your
				Subscription Service in accordance with the procedures set out in our Client
				Agreement, Terms of Use and Privacy Policy. This term shall apply except where
				we are required by applicable law to retain some or all the Customer Data, or
				where we have archived Customer Data on back-up systems, which data we will
				securely isolate and protect from any further Processing and delete in
				accordance with our deletion practices. You may request the deletion of your TrafficManager
				account after expiration or termination of your subscription by sending a
				request to our support. You may also cancel your account in accordance with the
				section "4.Platform Account and Agreement Termination" of our Client Agreement
				(https://www.trafficmanager.com/legal#client-agreement/ ) and request permanent
				deletion and/or retrieve your Customer Data from your account by opening a
				support ticket with us.</p>

			<p class=MsoNormal style='margin-left:36.0pt;line-height:115%'>&nbsp;</p>

			<p class=MsoNormal style='line-height:115%'><b><span style='font-size:12.0pt;
line-height:115%'>4. Data Subject Requests</span></b></p>

			<p class=MsoNormal style='line-height:115%'>The Subscription Service provides
				you with a number of controls that you can use to retrieve, correct, delete or
				restrict Personal Data, which you can use to assist it in connection with its
				obligations under Data Protection Laws, including your obligations relating to
				responding to requests from Data Subjects to exercise their rights under
				applicable Data Protection Laws (&quot;Data Subject Requests&quot;). To the
				extent that you are unable to independently address a Data Subject Request
				through the Subscription Service, then upon your written request we will
				provide reasonable assistance to you to respond to any Data Subject Requests or
				requests from data protection authorities relating to the Processing of
				Personal Data under the Agreement. You shall reimburse us for the commercially
				reasonable costs arising from this assistance. If a Data Subject Request or
				other communication regarding the Processing of Personal Data under the
				Agreement is made directly to us, we will promptly inform you and will advise
				the Data Subject to submit their request to you. You will be solely responsible
				for responding substantively to any such Data Subject Requests or communications
				involving Personal Data.</p>

			<p class=MsoNormal style='line-height:115%'>&nbsp;</p>

			<p class=MsoNormal style='line-height:115%'><b><span style='font-size:12.0pt;
line-height:115%'>5. Sub-Processors</span></b></p>

			<p class=MsoNormal style='line-height:115%'>You agree that we may engage
				Sub-Processors to Process Personal Data on your behalf. We have currently
				appointed, as Sub-Processors, the TrafficManager Affiliates and third parties
				listed in Annex 3 to this DPA. We will notify you if we add or replace any
				Sub-Processors listed in Annex 3 prior to any such changes at least 30 days
				prior to any such changes, if you opt-in to receive such email notifications via
				your account settings. Where we engage Sub-Processors, we will impose data
				protection terms on the Sub-Processors that provide at least the same level of
				protection for Personal Data as those in this DPA (including, where
				appropriate, the Standard Contractual Clauses), to the extent applicable to the
				nature of the services provided by such Sub-Processors. We will remain
				responsible for each Sub-Processor`s compliance with the obligations of this
				DPA and for any acts or omissions of such Sub-Processor that cause us to breach
				any of its obligations under this DPA.</p>

			<p class=MsoNormal style='line-height:115%'><b><span style='font-size:12.0pt;
line-height:115%'>6. Location and transfer of Personal Data</span></b></p>

			<p class=MsoNormal style='margin-left:36.0pt;text-indent:-18.0pt'>a)<span
					style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>The
				Service allows the Client to choose the geographical area where their platform
				will be installed at the time of the first configuration ("Datacenter location".
				Specifically, if the location chosen is "Europe", TrafficManager does not store,
				without the Client"s prior approval, any Client data outside the European Union.</p>

			<p class=MsoNormal style='margin-left:36.0pt;text-indent:-18.0pt'>b)<span
					style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>Subject
				to the foregoing Datacenters" location provision, TrafficManager and authorized
				Sub-Processors may remotely process Client"s Content provided that such
				processing operations occur as needed for the carrying out of the Services, and
				in particular, in relation to security and service maintenance purposes.</p>

			<p class=MsoNormal style='margin-left:36.0pt;text-indent:-18.0pt'>c)<span
					style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>Concerning
				the utilization of Services located in non-European Datacenters, (a) the
				Datacenters may be located in countries which are not subjected to an adequacy
				decision of European Commission pursuant to article 45 of the GDPR ("Adequacy
				Decision") and/or (b) the Client"s Content may, according to sections 6.2 and 7
				of this DPA, be processed from countries not subjected to an Adequacy Decision.</p>

			<p class=MsoNormal>Wherever Personal Data is transferred, for any reason in
				accordance with this DPA, outside its country of origin, each party will ensure
				such transfers are made in compliance with the requirements of Data Protection
				Laws.</p>

			<p class=MsoNormal style='line-height:115%'><b><span style='font-size:12.0pt;
line-height:115%'>7. Additional Provisions for European Data</span></b></p>

			<ol style='margin-top:0cm' start=1 type=a>
				<li class=MsoNormal style='line-height:115%'>Scope. </li>
			</ol>

			<p class=MsoNormal style='margin-left:36.0pt;line-height:115%'>This 'Additional
				Provisions for European Data' section shall apply only with respect to European
				Data.</p>

			<ol style='margin-top:0cm' start=2 type=a>
				<li class=MsoNormal style='line-height:115%'>Roles of the Parties. </li>
			</ol>

			<p class=MsoNormal style='margin-left:36.0pt;line-height:115%'>When Processing
				European Data in accordance with your Instructions, the parties acknowledge and
				agree that you are the Controller of European Data and we are the Processor.</p>

			<ol style='margin-top:0cm' start=3 type=a>
				<li class=MsoNormal style='line-height:115%'>Instructions. </li>
			</ol>

			<p class=MsoNormal style='margin-left:36.0pt;line-height:115%'>If we believe
				that your Instruction infringes European Data Protection Laws (where
				applicable), we will inform you without delay.</p>

			<ol style='margin-top:0cm' start=4 type=a>
				<li class=MsoNormal style='line-height:115%'>Objection to New Sub-Processors. </li>
			</ol>

			<p class=MsoNormal style='margin-left:36.0pt;line-height:115%'>We will give you
				the opportunity to object to the engagement of new Sub-Processors on reasonable
				grounds relating to the protection of Personal Data within 30 days of notifying
				you in accordance with the "Sub-Processors" section. If you do notify us of
				such an objection, the parties will discuss your concerns in good faith with a
				view to achieving a commercially reasonable resolution. If no such resolution
				can be reached, we will, at our sole discretion, either not appoint the new
				Sub-Processor, or permit you to suspend or terminate the affected Subscription
				Service in accordance with the termination provisions of the Agreement without
				liability to either party (but without prejudice to any fees incurred by you
				prior to suspension or termination). The parties agree that by complying with
				this sub-section (d), TrafficManager fulfils its obligations under its Client
				Agreement.</p>

			<ol style='margin-top:0cm' start=5 type=a>
				<li class=MsoNormal style='line-height:115%'>Sub-Processor Agreements. </li>
			</ol>

			<p class=MsoNormal style='margin-left:36.0pt;line-height:115%'>You acknowledge
				that we may be restricted from disclosing Sub-Processor agreements, but we shall
				use reasonable efforts to require any Sub-Processor we appoint to permit it to
				disclose the Sub-Processor agreement to you and shall provide (on a
				confidential basis) all information we reasonably can.</p>

			<ol style='margin-top:0cm' start=6 type=a>
				<li class=MsoNormal style='line-height:115%'>Data Protection Impact
					Assessments and Consultation with Supervisory Authorities.</li>
			</ol>

			<p class=MsoNormal style='margin-left:36.0pt;line-height:115%'>To the extent
				that the required information is reasonably available to us, and you do not
				otherwise have access to the required information, we will provide reasonable
				assistance to you with any data protection impact assessments, and prior
				consultations with supervisory authorities or other competent data privacy
				authorities to the extent required by European Data Protection Laws.</p>

			<ol style='margin-top:0cm' start=7 type=a>
				<li class=MsoNormal style='line-height:115%'>Transfer Mechanisms for Data
					Transfers.</li>
			</ol>

			<p class=MsoNormal style='margin-left:72.0pt;text-indent:-18.0pt;line-height:
115%'><span style='font-family:Wingdings'>"<span style='font:7.0pt "Times New Roman"'>&nbsp;
					</span></span>G1) TrafficManager shall not transfer European Data to any
				country or recipient not recognized as providing an adequate level of
				protection for Personal Data (within the meaning of applicable European Data
				Protection Laws), unless it first takes all such measures as are necessary to
				ensure the transfer is in compliance with applicable European Data Protection
				Laws. Such measures may include (without limitation) transferring such data to
				a recipient that is covered by a suitable framework or other legally adequate
				transfer mechanism recognized by the relevant authorities or courts as
				providing an adequate level of protection for Personal Data, to a recipient
				that has achieved binding corporate rules authorization in accordance with
				European Data Protection Laws, or to a recipient that has executed appropriate
				standard contractual clauses in each case as adopted or approved in accordance
				with applicable European Data Protection Laws. </p>

			<p class=MsoNormal style='margin-left:72.0pt;text-indent:-18.0pt;line-height:
115%'><span style='font-family:Wingdings'>"<span style='font:7.0pt "Times New Roman"'>&nbsp;
					</span></span>G2) The parties acknowledge and agree the following: "</p>

			<p class=MsoNormal style='margin-left:54.0pt;line-height:115%'>(a) Standard
				Contractual Clauses: The parties agree to abide by and process European Data in
				compliance with the Standard Contractual Clauses</p>

			<p class=MsoNormal style='margin-left:54.0pt;line-height:115%'>(b) The parties
				agree that for the purposes of the Standard Contractual Clauses, (i) TrafficManager
				will be the &quot;data importer&quot; and Customer will be the &quot;data
				exporter&quot; (on behalf of itself and Permitted Affiliates); "(ii) the
				Annexes of the Standard Contractual Clauses shall be populated with the
				relevant information set out in Annex 1 and Annex 2 of this DPA; "(iii) where
				the TrafficManager contracting entity under the Agreement is not TrafficManager,
				such contracting entity (not TrafficManager) will remain fully and responsible
				and liable to you for the performance of the Standard Contractual Clauses by TrafficManager,
				and you will direct any instructions, claims or enquiries in relation to the
				Standard Contractual Clauses to such contracting entity; and "(iv) if and to
				the extent the Standard Contractual Clauses conflict with any provision of this
				DPA, the Standard Contractual Clauses will prevail to the extent of such
				conflict. </p>

			<p class=MsoNormal style='margin-left:54.0pt;line-height:115%'>(c) To extent
				that and for so long as the Standard Contractual Clauses as implemented in
				accordance with this DPA cannot be relied on by the parties to lawfully
				transfer Personal Data in compliance with the UK GDPR, the applicable standard
				data protection clauses issued, adopted or permitted under the UK GDPR shall be
				incorporated by reference, and the annexes, appendices or tables of such
				clauses shall be deemed populated with the relevant information set out in
				Annex 1 and Annex 2 of this DPA. </p>

			<p class=MsoNormal style='margin-left:54.0pt;line-height:115%'>(d) If for any
				reason TrafficManager cannot comply with its obligations under the Standard
				Contractual Clauses or is breach of any warranties under the Standard
				Contractual Clauses, and you intend to suspend the transfer of European Data to
				TrafficManager or terminate the Standard Contractual Clauses, you agree to
				provide us with reasonable notice to enable us to cure such non-compliance and
				reasonably cooperate with us to identify what additional safeguards, if any,
				may be implemented to remedy such non-compliance. If we have not or cannot cure
				the non-compliance, you may suspend or terminate the affected part of the
				Subscription Service in accordance with the Agreement without liability to
				either party (but without prejudice to any fees you have incurred prior to such
				suspension or termination). </p>

			<p class=MsoNormal style='line-height:115%'>&nbsp;</p>

			<ol style='margin-top:0cm' start=8 type=a>
				<li class=MsoNormal style='line-height:115%'>Demonstration of Compliance. </li>
			</ol>

			<p class=MsoNormal style='margin-left:36.0pt;text-align:justify;line-height:
115%'>TrafficManager shall maintain records of its security standards. Upon
				Customer's written request, TrafficManager shall provide (on a confidential
				basis) copies of relevant audit report summaries and/or other documentation
				reasonably required by Customer to verify TrafficManager compliance with this
				DPA. TrafficManager shall further provide written responses (on a confidential
				basis) to all reasonable requests for information made by Customer, including
				responses to information security and audit questionnaires, that Customer
				(acting reasonably) considers necessary to confirm TrafficManager's compliance
				with this DPA, provided that Customer shall not exercise this right more than
				once per year.</p>

			<p class=MsoNormal style='margin-left:36.0pt;text-align:justify;line-height:
115%'>To the extent the Standard Contractual Clauses apply and the Customer
				reasonably argues and establishes that the above documentation and/or other
				third party audit reports are not sufficient to demonstrate compliance with the
				obligations laid down in this DPA, the Customer may execute an audit as
				outlined under Clause 8.9 of the Standard Contractual Clauses accordingly,
				provided that in such an event, the parties agree: (a) Customer is responsible
				for all costs and fees relating to such audit (including for time, cost and
				materials expended by TrafficManager); (b) a third party auditor must be
				mutually agreed upon between the parties to follow industry standard and
				appropriate audit procedures; (c) such audit must not unreasonably interfere
				with TrafficManager"s business activities and must be reasonable in time and
				scope; and (d) the parties must agree to a specific audit plan prior to any
				such audit, which must be negotiated in good faith between the parties.</p>

			<p class=MsoNormal style='margin-left:36.0pt;line-height:115%'>&nbsp;</p>

			<p class=MsoNormal style='line-height:115%'><b><span style='font-size:12.0pt;
line-height:115%'>9. General Provisions</span></b></p>

			<ol style='margin-top:0cm' start=1 type=a>
				<li class=MsoNormal style='line-height:115%'>Amendments. </li>
			</ol>

			<p class=MsoNormal style='margin-left:36.0pt;line-height:115%'>Notwithstanding
				anything else to the contrary in the Agreement and without prejudice to the
				"Compliance with Instructions" or "Security" sections of this DPA, we reserve
				the right to make any updates and changes to this DPA.</p>

			<ol style='margin-top:0cm' start=2 type=a>
				<li class=MsoNormal style='line-height:115%'>Severability. </li>
			</ol>

			<p class=MsoNormal style='margin-left:36.0pt;line-height:115%'>If any
				individual provisions of this DPA are determined to be invalid or
				unenforceable, the validity and enforceability of the other provisions of this
				DPA will not be affected.</p>

			<ol style='margin-top:0cm' start=3 type=a>
				<li class=MsoNormal style='line-height:115%'>Limitation of Liability. </li>
			</ol>

			<p class=MsoNormal style='margin-left:36.0pt;line-height:115%'>Each party and
				each of their Affiliates' liability, taken in aggregate, arising out of or
				related to this DPA (and any other DPAs between the parties) and the Standard
				Contractual Clauses (where applicable), whether in contract, tort or under any
				other theory of liability, will be subject to the limitations and exclusions of
				liability set out in the 'Limitation of Liability' section of Client Agreement
				and any reference in such section to the liability of a party means aggregate
				liability of that party and all of its Affiliates under the Agreement
				(including this DPA). For the avoidance of doubt, if TrafficManager is not a
				party to the Agreement, the "Limitation of Liability" section of the Client
				Agreement will apply as between you and TrafficManager, and in such respect any
				references to "TrafficManager", "we", "us" or "our" will include both TrafficManager
				and the TrafficManager entity that is a party to the Agreement. In no event
				shall either party's liability be limited with respect to any individual's data
				protection rights under this DPA (including the Standard Contractual Clauses)
				or otherwise.</p>

			<ol style='margin-top:0cm' start=4 type=a>
				<li class=MsoNormal style='line-height:115%'>Governing Law. </li>
			</ol>

			<p class=MsoNormal style='margin-left:36.0pt;line-height:115%'>This DPA will be
				governed by the laws of Romania, EU, without regard to principles of conflict
				of laws, unless required otherwise by Data Protection Laws.</p>

			<p class=MsoNormal style='margin-left:36.0pt;line-height:115%'>&nbsp;</p>

			<p class=MsoNormal style='line-height:115%'><b><span style='font-size:12.0pt;
line-height:115%'>10. Parties to this DPA</span></b></p>

			<ol style='margin-top:0cm' start=1 type=a>
				<li class=MsoNormal style='line-height:115%'>Permitted Affiliates. </li>
			</ol>

			<p class=MsoNormal style='margin-left:36.0pt;line-height:115%'>By signing the
				Agreement, you enter into this DPA (including, where applicable, the Standard
				Contractual Clauses) on behalf of yourself and in the name and on behalf of
				your Permitted Affiliates. For the purposes of this DPA only, and except where
				indicated otherwise, the terms "Customer", "you" and "your" will include you
				and such Permitted Affiliates.</p>

			<ol style='margin-top:0cm' start=2 type=a>
				<li class=MsoNormal style='line-height:115%'>Authorization.</li>
			</ol>

			<p class=MsoNormal style='margin-left:36.0pt;line-height:115%'>The legal entity
				agreeing to this DPA as Customer represents that it is authorized to agree to
				and enter into this DPA for and on behalf of itself and, as applicable, each of
				its Permitted Affiliates.</p>

			<ol style='margin-top:0cm' start=3 type=a>
				<li class=MsoNormal style='line-height:115%'>Remedies. </li>
			</ol>

			<p class=MsoNormal style='margin-left:36.0pt;line-height:115%'>The parties
				agree that (i) solely the Customer entity that is the contracting party to the
				Agreement will exercise any right or seek any remedy any Permitted Affiliate
				may have under this DPA on behalf of its Affiliates, and (ii) the Customer
				entity that is the contracting party to the Agreement will exercise any such
				rights under this DPA not separately for each Permitted Affiliate individually
				but in a combined manner for itself and all of its Permitted Affiliates
				together. The Customer entity that is the contracting entity is responsible for
				coordinating all Instructions, authorizations and communications with us under
				the DPA and will be entitled to make and receive any communications related to
				this DPA on behalf of its Permitted Affiliates. </p>

			<ol style='margin-top:0cm' start=4 type=a>
				<li class=MsoNormal style='line-height:115%'>Other rights. </li>
			</ol>

			<p class=MsoNormal style='margin-left:36.0pt;line-height:115%'>The parties
				agree that you will, when reviewing our compliance with this DPA pursuant to
				the Demonstration of Compliance" section, take all reasonable measures to limit
				any impact on us and our Affiliates by combining several audit requests carried
				out on behalf of the Customer entity that is the contracting party to the
				Agreement and all of its Permitted Affiliates in one single audit.</p>

			<p class=MsoNormal style='margin-left:36.0pt;line-height:115%'>&nbsp;</p>

			<p class=MsoNormal style='line-height:115%'>EXECUTED BY THE PARTIES AUTHORIZED
				REPRESENTATIVES:</p>

			<p class=MsoNormal align=center style='text-align:center;line-height:115%'>&nbsp;</p>

			<p class=MsoNormal style='line-height:200%'>TrafficManager, by and on behalf of
				its affiliates, as applicable. </p>

			<p class=MsoNormal style='line-height:200%'><span lang=IT>Name: Francesco
					Montanari </span></p>

			<p class=MsoNormal style='line-height:200%'>Title: CTO &amp; Data Protection Officer</p>

			<p class=MsoNormal style='line-height:115%'>&nbsp;</p>

			<p class=MsoNormal style='line-height:115%'>Signature: </p>

			<p class=MsoNormal>
				<img src="signature.png" alt="signature" width="300">
			</p>

			<p class=MsoNormal style='line-height:115%'>&nbsp;</p>

			<p class=MsoNormal style='line-height:115%'>&nbsp;</p>

			<p class=MsoNormal style='line-height:200%'>Controller, by and on behalf of its
				affiliates, as applicable.</p>

			<p class=MsoNormal style='line-height:200%'>Name (written out in full): {{ customer.name }}</p>

			<p class=MsoNormal style='line-height:200%'>Company: {{ customer.company }}</p>

			<p class=MsoNormal style='line-height:200%'>Title: {{ customer.title }}</p>

			<p class=MsoNormal style='line-height:115%'>&nbsp;</p>

			<p class=MsoNormal style='line-height:115%'>Signature: </p>

			<img v-if="customer.signature != ''" id="signatureImg" class="signature" :src="customer.signature"
				alt="signature">

			<p class=MsoNormal style='line-height:115%'>&nbsp;</p>

			<p class=MsoNormal style='margin-left:288.0pt;line-height:115%'>&nbsp;</p>

			<p class=MsoNormal style='line-height:115%'>&nbsp;</p>

			<p class=MsoNormal style='line-height:115%'>&nbsp;</p>

			<p class=MsoNormal style='line-height:115%'>&nbsp;</p>

			<p class=MsoNormal style='line-height:115%'>&nbsp;</p>

			<p class=MsoNormal align=center style='text-align:center;line-height:115%'><b><span
						style='font-size:22.0pt;line-height:115%'>Annex 1 - Details of Processing</span></b></p>

			<p class=MsoNormal align=center style='text-align:center;line-height:115%'><b><span
						style='font-size:22.0pt;line-height:115%'>&nbsp;</span></b></p>

			<p class=MsoNormal style='margin-left:36.0pt;text-indent:-18.0pt;line-height:
115%'><b><span style='font-size:14.0pt;line-height:115%'>1.<span
							style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span
						style='font-size:14.0pt;line-height:115%'>List of Parties</span></b></p>

			<p class=MsoNormal style='line-height:115%'>&nbsp;</p>

			<p class=MsoNormal style='line-height:115%'><b>Data exporter:</b></p>

			<p class=MsoNormal style='line-height:115%'>Name: The Customer, or the User, as
				defined in the TrafficManager Terms of Use (on behalf of itself and Permitted
				Affiliates)</p>

			<p class=MsoNormal style='line-height:115%'>Address: The Customer's address,
				as set out in the Client account accessible from https://www.trafficmanager.com/login
			</p>

			<p class=MsoNormal style='line-height:115%'>Contact person`s name, position,
				and contact details: </p>

			<p class=MsoNormal style='line-height:115%'>The Customer's contact details, as
				set out in the Client account, as set out in the Customer`s TrafficManager
				Account Activities relevant to the data transferred under these Clauses:
				Processing of Personal Data in connection with Customer's use of the TrafficManager
				Services under the TrafficManager Customer Terms of Service and Client
				Agreement</p>

			<p class=MsoNormal style='line-height:115%'><span lang=IT>Role
					(controller/processor): Controller</span></p>

			<p class=MsoNormal style='line-height:115%'><span lang=IT>&nbsp;</span></p>

			<p class=MsoNormal style='line-height:115%'><b><span lang=IT>Data importer:</span></b></p>

			<p class=MsoNormal style='line-height:115%'>Name: Traffic Manager Group SRL</p>

			<p class=MsoNormal style='line-height:115%'>Address: Mihail Kogalniceanu 17,
				bloc C4, city Brasov, Romania</p>

			<p class=MsoNormal style='line-height:115%'>Contact person`s name, position and
				contact details: </p>

			<p class=MsoNormal style='line-height:115%'>Francesco Montanari, CTO &amp; Data
				Protection Officer, email: gdpr@trafficmanager.com</p>

			<p class=MsoNormal style='line-height:115%'>Activities relevant to the data
				transferred under these Clauses: </p>

			<p class=MsoNormal style='line-height:115%'>Processing of Personal Data in
				connection with Customer's use of the TrafficManager Services under the TrafficManager
				Customer Terms of Service and Client Agreement</p>

			<p class=MsoNormal style='line-height:115%'>Role (controller/processor):
				Processor</p>

			<p class=MsoNormal style='line-height:115%'>&nbsp;</p>

			<ol style='margin-top:0cm' start=2 type=1>
				<li class=MsoNormal style='line-height:115%'><b><span style='font-size:12.0pt;
     line-height:115%'>Description of Transfer</span></b></li>
			</ol>

			<p class=MsoNormal style='margin-left:36.0pt;text-indent:-18.0pt;line-height:
115%'>a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				</span>Categories of Data Subjects whose Personal Data is Transferred </p>

			<p class=MsoNormal style='line-height:115%'>You may submit Personal Data in the
				course of using the TrafficManager Service, the extent of which is determined
				and controlled by you in your sole discretion, and which may include, but is
				not limited to Personal Data relating to the following categories of Data
				Subjects:</p>

			<p class=MsoNormal style='line-height:115%'>Your Contacts and other end users
				including your employees, contractors, collaborators, customers, prospects, suppliers
				and subcontractors. Data Subjects may also include individuals attempting to
				communicate with or transfer Personal Data to your end users.</p>

			<p class=MsoNormal style='margin-left:36.0pt;text-indent:-18.0pt;line-height:
115%'>b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				</span>Categories of Personal Data Transferred</p>

			<p class=MsoNormal style='line-height:115%'>You may submit Personal Data to the
				Subscription Services, the extent of which is determined and controlled by you
				in your sole discretion, and which may include but is not limited to the
				following categories of Personal Data: </p>

			<p class=MsoNormal style='margin-left:36.0pt;text-indent:-18.0pt;line-height:
115%'><span style='font-family:Wingdings'>"<span style='font:7.0pt "Times New Roman"'>&nbsp;
					</span></span>Contact Information (as defined in the Client Agreement).</p>

			<p class=MsoNormal style='margin-left:36.0pt;text-indent:-18.0pt;line-height:
115%'><span style='font-family:Wingdings'>"<span style='font:7.0pt "Times New Roman"'>&nbsp;
					</span></span>Any other Personal Data submitted by, sent to, or received by
				you, or your end users, via the TrafficManager Service. Sensitive Data
				transferred and applied restrictions or safeguards. The parties do not
				anticipate the transfer of sensitive data.</p>

			<p class=MsoNormal style='margin-left:36.0pt;text-indent:-18.0pt;line-height:
115%'>c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				</span>Frequency of the transfer</p>

			<p class=MsoNormal style='line-height:115%'>Continuous</p>

			<p class=MsoNormal style='margin-left:36.0pt;text-indent:-18.0pt;line-height:
115%'>d)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				</span>Nature of the Processing</p>

			<p class=MsoNormal style='line-height:115%'>Personal Data will be Processed in
				accordance with the Agreement (including this DPA) and may be subject to the
				following Processing activities:</p>

			<p class=MsoNormal style='margin-left:36.0pt;text-indent:-18.0pt;line-height:
115%'><span style='font-family:Wingdings'>"<span style='font:7.0pt "Times New Roman"'>&nbsp;
					</span></span>Storage and other Processing necessary to provide, maintain and
				improve the Subscription Services provided to you; and/or</p>

			<p class=MsoNormal style='margin-left:36.0pt;text-indent:-18.0pt;line-height:
115%'><span style='font-family:Wingdings'>"<span style='font:7.0pt "Times New Roman"'>&nbsp;
					</span></span>Disclosure in accordance with the Agreement (including this DPA)
				and/or as compelled by applicable laws.</p>

			<p class=MsoNormal style='margin-left:36.0pt;text-indent:-18.0pt;line-height:
115%'>e)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				</span>Purpose of the transfer and further processing</p>

			<p class=MsoNormal style='line-height:115%'>We will Process Personal Data as
				necessary to provide the Subscription Services pursuant to the Agreement, as
				further specified in the Client Agreement, and as further instructed by you in
				your use of the TrafficManager Services.</p>

			<p class=MsoNormal style='margin-left:36.0pt;text-indent:-18.0pt;line-height:
115%'>f)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				</span>Period for which Personal Data will be retained</p>

			<p class=MsoNormal style='line-height:115%'>Subject to the 'Deletion or Return
				of Personal Data' section of this DPA, we will Process Personal Data for the
				duration of the Agreement, unless otherwise agreed in writing. </p>

			<p class=MsoNormal style='line-height:115%'>&nbsp;</p>

			<ol style='margin-top:0cm' start=3 type=1>
				<li class=MsoNormal style='line-height:115%'><b><span style='font-size:12.0pt;
     line-height:115%'>Competent Supervisory Authority</span></b></li>
			</ol>

			<p class=MsoNormal style='line-height:115%'>For the purposes of the Standard
				Contractual Clauses, the supervisory authority that shall act as competent
				supervisory authority is either (i) where Customer is established in an EU
				Member State, the supervisory authority responsible for ensuring Customer's
				compliance with the GDPR; (ii) where Customer is not established in an EU
				Member State but falls within the extra-territorial scope of the GDPR and has
				appointed a representative, the supervisory authority of the EU Member State in
				which Customer's representative is established; or (iii) where Customer is not
				established in an EU Member State but falls within the extra-territorial scope
				of the GDPR without having to appoint a representative, the supervisory
				authority of the EU Member State in which the Data Subjects are predominantly
				located. In relation to Personal Data that is subject to the UK GDPR or Swiss
				DPA, the competent supervisory authority is the UK Information Commissioner or
				the Swiss Federal Data Protection and Information Commissioner (as applicable).</p>

			<p class=MsoNormal style='line-height:115%'>&nbsp;</p>

			<p class=MsoNormal align=center style='text-align:center;line-height:115%'><b><span
						style='font-size:20.0pt;line-height:115%'>Annex 2 - Security Measures</span></b></p>

			<p class=MsoNormal style='line-height:115%'>This Annex forms part of the DPA. We
				currently observe the Security Measures described in this Annex 2. All
				capitalized terms not otherwise defined herein shall have the meanings as set
				forth in our Client Agreement, Terms of Use and Privacy Policy.</p>

			<p class=MsoNormal style='margin-left:36.0pt;text-indent:-18.0pt;line-height:
115%'><b><span style='font-size:12.0pt;line-height:115%'>1)<span
							style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span
						style='font-size:12.0pt;line-height:115%'>Access Control</span></b></p>

			<p class=MsoNormal style='margin-left:36.0pt;text-indent:-18.0pt;line-height:
115%'>a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				</span>"Preventing Unauthorized Product Access</p>

			<p class=MsoNormal style='line-height:115%'>Outsourced processing: We host our
				Service with outsourced cloud infrastructure providers. Additionally, we
				maintain contractual relationships with vendors in order to provide the Service
				in accordance with our DPA. We rely on contractual agreements, privacy
				policies, and vendor compliance programs in order to protect data processed or
				stored by these vendors.</p>

			<p class=MsoNormal style='line-height:115%'>Physical and environmental
				security: We host our product infrastructure with multitenant, outsourced
				infrastructure providers. The physical and environmental security controls are
				audited for SOC 2 Type II and ISO 27001 compliance, among other certifications.</p>

			<p class=MsoNormal style='line-height:115%'>Authentication: We implement a
				uniform password policy for our customer products. </p>

			<p class=MsoNormal style='line-height:115%'>Customers who interact with the
				products via the user interface must authenticate before accessing non-public
				customer data.</p>

			<p class=MsoNormal style='line-height:115%'>Authorization: Customer Data is
				stored in multi-tenant storage systems accessible to Customers via only
				application user interfaces and application programming interfaces. Customers
				are not allowed direct access to the underlying application infrastructure. The
				authorization model in each of our products is designed to ensure that only the
				appropriately assigned individuals can access relevant features, views, and
				customization options. Authorization to data sets is performed through
				validating the user`s permissions against the attributes associated with each
				data set.</p>

			<p class=MsoNormal style='line-height:115%'>Application Programming Interface
				(API) access: Public product APIs may be accessed using an API key or through
				Oauth authorization.</p>

			<p class=MsoNormal style='margin-left:36.0pt;text-indent:-18.0pt;line-height:
115%'>b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				</span>Preventing Unauthorized Product Use</p>

			<p class=MsoNormal style='line-height:115%'>We implement industry standard
				access controls and detection capabilities for the internal networks that
				support its products.</p>

			<p class=MsoNormal style='line-height:115%'>Access controls: Network access
				control mechanisms are designed to prevent network traffic using unauthorized
				protocols from reaching the product infrastructure. The technical measures
				implemented differ between infrastructure providers and include Virtual Private
				Cloud (VPC) implementations, security group assignment, and traditional
				firewall rules.</p>

			<p class=MsoNormal style='line-height:115%'>Intrusion detection and prevention:
				We implement a Web Application Firewall (WAF) solution to protect hosted
				customer websites and other internet-accessible applications. The WAF is
				designed to identify and prevent attacks against publicly available network
				services.</p>

			<p class=MsoNormal style='line-height:115%'>Static code analysis: Security
				reviews of code stored in our source code repositories is performed, checking
				for coding best practices and identifiable software flaws.</p>

			<p class=MsoNormal style='line-height:115%'>Penetration testing: We maintain
				relationships with industry recognized penetration testing service providers
				for four annual penetration tests. The intent of the penetration tests is to
				identify and resolve foreseeable attack vectors and potential abuse scenarios.</p>

			<p class=MsoNormal style='line-height:115%'>Bug bounty: A bug bounty program
				invites and incentivizes independent security researchers to ethically discover
				and disclose security flaws. We implement a bug bounty program in an effort to
				widen the available opportunities to engage with the security community and
				improve the product defenses against sophisticated attacks.</p>

			<p class=MsoNormal style='margin-left:36.0pt;text-indent:-18.0pt;line-height:
115%'>c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				</span>Limitations of Privilege &amp; Authorization Requirements</p>

			<p class=MsoNormal style='line-height:115%'>Product access: A subset of our
				employees have access to the products and to customer data via controlled
				interfaces. The intent of providing access to a subset of employees is to
				provide effective customer support, to troubleshoot potential problems, to detect
				and respond to security incidents and implement data security. Access is
				enabled through "just in time" requests for access; all such requests are
				logged. Employees are granted access by role, and reviews of high risk
				privilege grants are initiated daily. Employee roles are reviewed at least once
				every six months.</p>

			<p class=MsoNormal style='line-height:115%'>Background checks: All TrafficManager
				employees undergo a third-party background check prior to being extended an
				employment offer, in accordance with and as permitted by the applicable laws.
				All TrafficManager employees are required to conduct themselves in a manner
				consistent with company guidelines, non-disclosure requirements, and ethical
				standards.</p>

			<p class=MsoNormal style='margin-left:36.0pt;text-indent:-18.0pt;line-height:
115%'><b><span style='font-size:12.0pt;line-height:115%'>2)<span
							style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span
						style='font-size:12.0pt;line-height:115%'>Transmission Control</span></b></p>

			<p class=MsoNormal style='line-height:115%'>In-transit: We make HTTPS
				encryption (also referred to as SSL or TLS) available on every one of its login
				interfaces and for free on every customer site hosted on the TrafficManager
				products. Our HTTPS implementation uses industry standard algorithms and
				certificates.</p>

			<p class=MsoNormal style='line-height:115%'>At-rest: We store user passwords
				following policies that follow industry standard practices for security. We
				have implemented technologies to ensure that stored data is encrypted at rest. </p>

			<p class=MsoNormal style='margin-left:36.0pt;text-indent:-18.0pt;line-height:
115%'><b><span style='font-size:12.0pt;line-height:115%'>3)<span
							style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span
						style='font-size:12.0pt;line-height:115%'>Input Control</span></b></p>

			<p class=MsoNormal style='line-height:115%'>Detection: We designed our
				infrastructure to log extensive information about the system behavior, traffic
				received, system authentication, and other application requests. Internal
				systems aggregated log data and alert appropriate employees of malicious,
				unintended, or anomalous activities. Our personnel, including security,
				operations, and support personnel, are responsive to known incidents.</p>

			<p class=MsoNormal style='line-height:115%'>Response and tracking: We maintain
				a record of known security incidents that includes description, dates and times
				of relevant activities, and incident disposition. Suspected and confirmed
				security incidents are investigated by security, operations, or support
				personnel; and appropriate resolution steps are identified and documented. For
				any confirmed incidents, we will take appropriate steps to minimize product and
				Customer damage or unauthorized disclosure. Notification to you will be in
				accordance with the terms of the Agreement. </p>

			<p class=MsoNormal style='margin-left:36.0pt;text-indent:-18.0pt;line-height:
115%'><b><span style='font-size:12.0pt;line-height:115%'>4)<span
							style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></b><b><span
						style='font-size:12.0pt;line-height:115%'>Availability Control</span></b></p>

			<p class=MsoNormal style='line-height:115%'>Infrastructure availability: The
				infrastructure providers use commercially reasonable efforts to ensure a
				minimum of 99.9% uptime. The providers maintain a minimum of N+1 redundancy to
				power, network, and HVAC services.</p>

			<p class=MsoNormal style='line-height:115%'>Fault tolerance: Backup and
				replication strategies are designed to ensure redundancy and fail-over
				protections during a significant processing failure. Customer data is backed up
				to multiple durable data stores and replicated across multiple availability
				zones.</p>

			<p class=MsoNormal style='line-height:115%'>Online replicas and backups: Where
				feasible, production databases are designed to replicate data between no less
				than 1 primary and 1 secondary database. All databases are backed up and
				maintained using at least industry standard methods. Our products are designed
				to ensure redundancy and seamless failover. The server instances that support
				the products are also architected with a goal to prevent single points of
				failure. This design assists our operations in maintaining and updating the
				product applications and backend while limiting downtime.</p>

			<b><span style='font-size:20.0pt;line-height:115%;font-family:"Calibri",sans-serif'><br clear=all
						style='page-break-before:always'>
				</span></b>

			<p class=MsoNormal align=center style='text-align:center;line-height:115%'><b><span
						style='font-size:20.0pt;line-height:115%'>Annex 3 - List of Sub-Processors</span></b></p>

			<table class="table" border=1 cellspacing=0 cellpadding=0>
				<tr style='height:16.6pt'>
					<td width=216 valign=top style='width:162.35pt;border:solid windowtext 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:16.6pt'>
						<p class=MsoNoSpacing align=center style='text-align:center'><b><span lang=IT
									style='font-size:12.0pt'>Entity name</span></b></p>
					</td>
					<td width=229 valign=top style='width:171.55pt;border:solid windowtext 1.0pt;
  border-left:none;padding:0cm 5.4pt 0cm 5.4pt;height:16.6pt'>
						<p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:115%'><b><span lang=IT style='font-size:12.0pt;line-height:115%'>Subprocessing
									Activity</span></b></p>
					</td>
					<td width=208 valign=top style='width:155.95pt;border:solid windowtext 1.0pt;
  border-left:none;padding:0cm 5.4pt 0cm 5.4pt;height:16.6pt'>
						<p class=MsoNormal align=center style='margin-bottom:0cm;text-align:center;
  line-height:115%'><b><span lang=IT style='font-size:12.0pt;line-height:115%'>Sub-Processor
									Location</span></b></p>
					</td>
				</tr>
				<tr style='height:38.55pt'>
					<td width=216 valign=top style='width:162.35pt;border:solid windowtext 1.0pt;
  border-top:none;padding:0cm 5.4pt 0cm 5.4pt;height:38.55pt'>
						<p class=MsoNormal style='margin-bottom:0cm;line-height:normal'><span lang=IT>OVH SAS</span></p>
					</td>
					<td width=229 valign=top style='width:171.55pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:38.55pt'>
						<p class=MsoNormal style='margin-bottom:0cm;line-height:normal'>Dedicated
							server hosting, client data storage, data processing</p>
					</td>
					<td width=208 valign=top style='width:155.95pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:38.55pt'>
						<p class=MsoNormal style='margin-bottom:0cm;line-height:normal'>Europe</p>
					</td>
				</tr>
				<tr style='height:13.6pt'>
					<td width=216 valign=top style='width:162.35pt;border:solid windowtext 1.0pt;
  border-top:none;padding:0cm 5.4pt 0cm 5.4pt;height:13.6pt'>
						<p class=MsoNormal style='margin-bottom:0cm;line-height:normal'><span lang=IT>OVH US LLC</span></p>
					</td>
					<td width=229 valign=top style='width:171.55pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:13.6pt'>
						<p class=MsoNormal style='margin-bottom:0cm;line-height:normal'>Dedicated
							server hosting, client data storage and processing</p>
					</td>
					<td width=208 valign=top style='width:155.95pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:13.6pt'>
						<p class=MsoNormal style='margin-bottom:0cm;line-height:normal'>United States</p>
						<p class=MsoNormal style='margin-bottom:0cm;line-height:normal'>Used only if
							the Customer choses a United States datacenter</p>
					</td>
				</tr>
				<tr style='height:13.6pt'>
					<td width=216 valign=top style='width:162.35pt;border:solid windowtext 1.0pt;
  border-top:none;padding:0cm 5.4pt 0cm 5.4pt;height:13.6pt'>
						<p class=MsoNormal style='margin-bottom:0cm;line-height:normal'><span
								style='font-family:"Segoe UI",sans-serif;color:#24292F;background:white'>SYMFONY
								SAS</span></p>
					</td>
					<td width=229 valign=top style='width:171.55pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:13.6pt'>
						<p class=MsoNormal style='margin-bottom:0cm;line-height:normal'><span
								style='color:black;background:white'>Source code analysis</span></p>
					</td>
					<td width=208 valign=top style='width:155.95pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:13.6pt'>
						<p class=MsoNormal style='margin-bottom:0cm;line-height:normal'><span
								style='color:black;background:white'>Europe</span></p>
					</td>
				</tr>
				<tr style='height:27.95pt'>
					<td width=216 valign=top style='width:162.35pt;border:solid windowtext 1.0pt;
  border-top:none;padding:0cm 5.4pt 0cm 5.4pt;height:27.95pt'>
						<p class=MsoNormal style='margin-bottom:0cm;line-height:normal'><span
								style='font-family:"Segoe UI",sans-serif;color:#24292F;background:white'>SolarWinds
								Software Europe DAC</span></p>
					</td>
					<td width=229 valign=top style='width:171.55pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:27.95pt'>
						<p class=MsoNormal style='margin-bottom:0cm;line-height:normal'><span
								style='color:black;background:white'>Server uptime monitoring</span></p>
					</td>
					<td width=208 valign=top style='width:155.95pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:27.95pt'>
						<p class=MsoNormal style='margin-bottom:0cm;line-height:normal'><span
								style='color:black;background:white'>Europe</span></p>
					</td>
				</tr>
				<tr style='height:38.55pt'>
					<td width=216 valign=top style='width:162.35pt;border:solid windowtext 1.0pt;
  border-top:none;padding:0cm 5.4pt 0cm 5.4pt;height:38.55pt'>
						<p class=MsoNormal style='margin-bottom:0cm;line-height:normal'>Google LLC</p>
					</td>
					<td width=229 valign=top style='width:171.55pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:38.55pt'>
						<p class=MsoNormal style='margin-bottom:0cm;line-height:normal'>Cloud
							provider, document processing, email processing, reCAPTCHA to prevent form
							submission spam</p>
					</td>
					<td width=208 valign=top style='width:155.95pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:38.55pt'>
						<p class=MsoNormal style='margin-bottom:0cm;line-height:normal'><span
								style='color:black;background:white'>United States, EU/US Privacy Shield + EU
								Clauses</span></p>
					</td>
				</tr>
				<tr style='height:51.4pt'>
					<td width=216 valign=top style='width:162.35pt;border:solid windowtext 1.0pt;
  border-top:none;padding:0cm 5.4pt 0cm 5.4pt;height:51.4pt'>
						<p class=MsoNormal style='margin-bottom:0cm;line-height:normal'>Microsoft
							Corp.</p>
					</td>
					<td width=229 valign=top style='width:171.55pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:51.4pt'>
						<p class=MsoNormal style='margin-bottom:0cm;line-height:normal'><span
								style='color:black;background:white'>Cloud provider, cloud file storage
								(OneDrive) to backup and sync source code, SSH keys and/or other technical
								procedures</span></p>
					</td>
					<td width=208 valign=top style='width:155.95pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:51.4pt'>
						<p class=MsoNormal style='margin-bottom:0cm;line-height:normal'><span
								style='color:black;background:white'>United States and Europe</span></p>
						<p class=MsoNormal style='margin-bottom:0cm;line-height:normal'><span
								style='color:black;background:white'>EU/US Privacy Shield + EU Clauses</span></p>
					</td>
				</tr>
				<tr style='height:25.7pt'>
					<td width=216 valign=top style='width:162.35pt;border:solid windowtext 1.0pt;
  border-top:none;padding:0cm 5.4pt 0cm 5.4pt;height:25.7pt'>
						<p class=MsoNormal style='margin-bottom:0cm;line-height:normal'>Dropbox Inc.</p>
					</td>
					<td width=229 valign=top style='width:171.55pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:25.7pt'>
						<p class=MsoNormal style='margin-bottom:0cm;line-height:normal'><span
								style='color:black;background:white'>Cloud file storage of financial
								documents (invoices and similar)</span></p>
					</td>
					<td width=208 valign=top style='width:155.95pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:25.7pt'>
						<p class=MsoNormal style='margin-bottom:0cm;line-height:normal'><span
								style='color:black;background:white'>United States,<br>
								EU/US Privacy Shield</span></p>
					</td>
				</tr>
				<tr style='height:38.55pt'>
					<td width=216 valign=top style='width:162.35pt;border:solid windowtext 1.0pt;
  border-top:none;padding:0cm 5.4pt 0cm 5.4pt;height:38.55pt'>
						<p class=MsoNormal style='margin-bottom:0cm;line-height:normal'><span
								style='font-family:"Segoe UI",sans-serif;color:#24292F;background:white'>GitHub
								Inc.</span></p>
					</td>
					<td width=229 valign=top style='width:171.55pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:38.55pt'>
						<p class=MsoNormal style='margin-bottom:0cm;line-height:normal'><span
								style='color:black;background:white'>Source code hosting and collaboration
								tools, code analysis</span></p>
					</td>
					<td width=208 valign=top style='width:155.95pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:38.55pt'>
						<p class=MsoNormal style='margin-bottom:0cm;line-height:normal'><span
								style='color:black;background:white'>United States</span></p>
						<p class=MsoNormal style='margin-bottom:0cm;line-height:normal'><span
								style='color:black;background:white'>Pseudonymous data only</span></p>
					</td>
				</tr>
				<tr style='height:71.5pt'>
					<td width=216 valign=top style='width:162.35pt;border:solid windowtext 1.0pt;
  border-top:none;padding:0cm 5.4pt 0cm 5.4pt;height:71.5pt'>
						<p class=MsoNormal style='margin-bottom:0cm;line-height:normal'><span
								style='font-family:"Segoe UI",sans-serif;color:#24292F;background:white'>CloudFlare,
								Inc.</span></p>
					</td>
					<td width=229 valign=top style='width:171.55pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:71.5pt'>
						<p class=MsoNormal style='margin-bottom:0cm;line-height:normal'><span
								style='color:black;background:white'>DNS services, CDN, Web Application
								Firewall, DDOS protection services</span></p>
					</td>
					<td width=208 valign=top style='width:155.95pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:71.5pt'>
						<p class=MsoNormal style='margin-bottom:0cm;line-height:normal'>Local: </p>
						<p class=MsoNormal style='margin-bottom:0cm;line-height:normal'>Data Centers
							located all around the world. Traffic will be automatically routed to the
							nearest data center.</p>
					</td>
				</tr>
			</table>

			<p class=MsoNormal align=center style='text-align:center;line-height:115%'><b><span
						style='font-size:20.0pt;line-height:115%'>&nbsp;</span></b></p>

			<p class=MsoNormal style='line-height:115%'>&nbsp;</p>

			<p class=MsoNormal align=center style='text-align:center;line-height:115%'><b><span
						style='font-size:20.0pt;line-height:115%'>Annex 4 - Standard Contractual
						Clauses</span></b></p>

			<p class=MsoNormal style='line-height:107%'><b>Module Two: Transfer Controller
					to Processor (C2P)</b></p>

			<p class=MsoNormal style='line-height:107%'><b>SECTION I</b></p>

			<p class=MsoNormal style='line-height:107%'><i>Clause 1</i></p>

			<p class=MsoNormal style='line-height:107%'><b><i>Purpose and scope</i></b></p>

			<p class=MsoNormal style='line-height:107%'>(a) The purpose of these standard
				contractual clauses is to ensure compliance with the requirements of Regulation
				(EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on
				the protection of natural persons with regard to the processing of personal
				data and on the free movement of such data (General Data Protection Regulation)
				for the transfer of personal data to a third country.</p>

			<p class=MsoNormal style='line-height:107%'>(b) The Parties:</p>

			<p class=MsoNormal style='line-height:107%'>(i) the natural or legal person(s),
				public authority/ies, agency/ies or other body/ies (hereinafter "entity/ies")
				transferring the personal data, as listed in Annex I.A. (hereinafter each "data
				exporter"), and</p>

			<p class=MsoNormal style='line-height:107%'>(ii) the entity/ies in a third
				country receiving the personal data from the data exporter, directly or
				indirectly via another entity also Party to these Clauses, as listed in Annex
				I.A. (hereinafter each "data importer")</p>

			<p class=MsoNormal style='line-height:107%'>have agreed to these standard
				contractual clauses (hereinafter: "Clauses").</p>

			<p class=MsoNormal style='line-height:107%'>(c) These Clauses apply with
				respect to the transfer of personal data as specified in Annex I.B.</p>

			<p class=MsoNormal style='line-height:107%'>(d) The Appendix to these Clauses
				containing the Annexes referred to therein forms an integral part of these
				Clauses.<br>
				<br>
			</p>

			<p class=MsoNormal style='line-height:107%'><i>Clause 2</i></p>

			<p class=MsoNormal style='line-height:107%'><b><i>Effect and invariability of
						the Clauses</i></b></p>

			<p class=MsoNormal style='line-height:107%'>(a) These Clauses set out
				appropriate safeguards, including enforceable data subject rights and effective
				legal remedies, pursuant to Article 46(1) and Article 46 (2)(c) of Regulation
				(EU) 2016/679 and, with respect to data transfers from controllers to processors
				and/or processors to processors, standard contractual clauses pursuant to
				Article 28(7) of Regulation (EU) 2016/679, provided they are not modified,
				except to select the appropriate Module(s) or to add or update information in
				the Appendix. This does not prevent the Parties from including the standard
				contractual clauses laid down in these Clauses in a wider contract and/or to
				add other clauses or additional safeguards, provided that they do not
				contradict, directly or indirectly, these Clauses or prejudice the fundamental
				rights or freedoms of data subjects.</p>

			<p class=MsoNormal style='line-height:107%'>(b) These Clauses are without
				prejudice to obligations to which the data exporter is subject by virtue of
				Regulation (EU) 2016/679.</p>

			<p class=MsoNormal style='line-height:107%'><i>Clause 3</i></p>

			<p class=MsoNormal style='line-height:107%'><b><i>Third-party beneficiaries</i></b></p>

			<p class=MsoNormal style='line-height:107%'>(a) Data subjects may invoke and
				enforce these Clauses, as third-party beneficiaries, against the data exporter
				and/or data importer, with the following exceptions:</p>

			<p class=MsoNormal style='line-height:107%'>(i) Clause 1, Clause 2, Clause 3,
				Clause 6, Clause 7;</p>

			<p class=MsoNormal style='line-height:107%'>(ii) Clause 8 - Clause 8.1(b),
				8.9(a), (c), (d) and (e);</p>

			<p class=MsoNormal style='line-height:107%'>(iii) Clause 9 - Clause 9(a), (c),
				(d) and (e);</p>

			<p class=MsoNormal style='line-height:107%'>(iv) Clause 12 - Clause 12(a), (d)
				and (f);</p>

			<p class=MsoNormal style='line-height:107%'><span lang=IT>(v) Clause 13;</span></p>

			<p class=MsoNormal style='line-height:107%'><span lang=IT>(vi) Clause 15.1(c),
					(d) and (e);</span></p>

			<p class=MsoNormal style='line-height:107%'>(vii) Clause 16(e);</p>

			<p class=MsoNormal style='line-height:107%'>(viii) Clause 18 - Clause 18(a) and
				(b).</p>

			<p class=MsoNormal style='line-height:107%'>(b) Paragraph (a) is without
				prejudice to rights of data subjects under Regulation (EU) 2016/679.</p>

			<p class=MsoNormal style='line-height:107%'><i>Clause 4</i></p>

			<p class=MsoNormal style='line-height:107%'><b><i>Interpretation</i></b></p>

			<p class=MsoNormal style='line-height:107%'>(a) Where these Clauses use terms
				that are defined in Regulation (EU) 2016/679, those terms shall have the same
				meaning as in that Regulation.</p>

			<p class=MsoNormal style='line-height:107%'>(b) These Clauses shall be read and
				interpreted in the light of the provisions of Regulation (EU) 2016/679.</p>

			<p class=MsoNormal style='line-height:107%'>(c) These Clauses shall not be
				interpreted in a way that conflicts with rights and obligations provided for in
				Regulation (EU) 2016/679.</p>

			<p class=MsoNormal style='line-height:107%'><i>Clause 5</i></p>

			<p class=MsoNormal style='line-height:107%'><b><i>Hierarchy</i></b></p>

			<p class=MsoNormal style='line-height:107%'>In the event of a contradiction
				between these Clauses and the provisions of related agreements between the
				Parties, existing at the time these Clauses are agreed or entered into
				thereafter, these Clauses shall prevail.</p>

			<p class=MsoNormal style='line-height:107%'><i>Clause 6</i></p>

			<p class=MsoNormal style='line-height:107%'><b><i>Description of the
						transfer(s)</i></b></p>

			<p class=MsoNormal style='line-height:107%'>The details of the transfer(s), and
				in particular the categories of personal data that are transferred and the
				purpose(s) for which they are transferred, are specified in Annex I.B.</p>

			<p class=MsoNormal style='line-height:107%'><i>Clause 7&nbsp;</i></p>

			<p class=MsoNormal style='line-height:107%'><b><i>Docking clause</i></b></p>

			<p class=MsoNormal style='line-height:107%'>(a) An entity that is not a Party
				to these Clauses may, with the agreement of the Parties, accede to these
				Clauses at any time, either as a data exporter or as a data importer, by
				completing the Appendix and signing Annex I.A.</p>

			<p class=MsoNormal style='line-height:107%'>(b) Once it has completed the
				Appendix and signed Annex I.A, the acceding entity shall become a Party to
				these Clauses and have the rights and obligations of a data exporter or data
				importer in accordance with its designation in Annex I.A.</p>

			<p class=MsoNormal style='line-height:107%'>(c) The acceding entity shall have
				no rights or obligations arising under these Clauses from the period prior to
				becoming a Party.</p>

			<p class=MsoNormal style='line-height:107%'>&nbsp;</p>

			<p class=MsoNormal style='line-height:107%'><b><u>SECTION II - OBLIGATIONS OF
						THE PARTIES</u></b></p>

			<p class=MsoNormal style='line-height:107%'><i>Clause 8</i></p>

			<p class=MsoNormal style='line-height:107%'><b><i>Data protection safeguards</i></b></p>

			<p class=MsoNormal style='line-height:107%'>The data exporter warrants that it
				has used reasonable efforts to determine that the data importer is able,
				through the implementation of appropriate technical and organizational
				measures, to satisfy its obligations under these Clauses.</p>

			<p class=MsoNormal style='line-height:107%'><b>8.1</b>&nbsp;<b>Instructions</b></p>

			<p class=MsoNormal style='line-height:107%'>(a) The data importer shall process
				the personal data only on documented instructions from the data exporter. The
				data exporter may give such instructions throughout the duration of the
				contract.</p>

			<p class=MsoNormal style='line-height:107%'>(b) The data importer shall
				immediately inform the data exporter if it is unable to follow those
				instructions.</p>

			<p class=MsoNormal style='line-height:107%'><b>8.2</b>&nbsp;<b>Purpose
					limitation</b></p>

			<p class=MsoNormal style='line-height:107%'>The data importer shall process the
				personal data only for the specific purpose(s) of the transfer, as set out in
				Annex I.B, unless on further instructions from the data exporter.<br>
				<br>
			</p>

			<p class=MsoNormal style='line-height:107%'><b>8.3</b>&nbsp;<b>Transparency</b></p>

			<p class=MsoNormal style='line-height:107%'>On request, the data exporter shall
				make a copy of these Clauses, including the Appendix as completed by the
				Parties, available to the data subject free of charge. To the extent necessary
				to protect business secrets or other confidential information, including the
				measures described in Annex II and personal data, the data exporter may redact
				part of the text of the Appendix to these Clauses prior to sharing a copy, but
				shall provide a meaningful summary where the data subject would otherwise not
				be able to understand its content or exercise his/her rights. On request, the
				Parties shall provide the data subject with the reasons for the redactions, to
				the extent possible without revealing the redacted information. This Clause is
				without prejudice to the obligations of the data exporter under Articles 13 and
				14 of Regulation (EU) 2016/679.</p>

			<p class=MsoNormal style='line-height:107%'><b>8.4</b>&nbsp;<b>Accuracy</b></p>

			<p class=MsoNormal style='line-height:107%'>If the data importer becomes aware
				that the personal data it has received is inaccurate, or has become outdated,
				it shall inform the data exporter without undue delay. In this case, the data
				importer shall cooperate with the data exporter to erase or rectify the data.</p>

			<p class=MsoNormal style='line-height:107%'><b>8.5</b>&nbsp;<b>Duration of
					processing and erasure or return of data</b></p>

			<p class=MsoNormal style='line-height:107%'>Processing by the data importer
				shall only take place for the duration specified in Annex I.B. After the end of
				the provision of the processing services, the data importer shall, at the
				choice of the data exporter, delete all personal data processed on behalf of
				the data exporter and certify to the data exporter that it has done so, or
				return to the data exporter all personal data processed on its behalf and
				delete existing copies. Until the data is deleted or returned, the data
				importer shall continue to ensure compliance with these Clauses. In case of
				local laws applicable to the data importer that prohibit return or deletion of
				the personal data, the data importer warrants that it will continue to ensure
				compliance with these Clauses and will only process it to the extent and for as
				long as required under that local law. This is without prejudice to Clause 14,
				in particular the requirement for the data importer under Clause 14(e) to
				notify the data exporter throughout the duration of the contract if it has
				reason to believe that it is or has become subject to laws or practices not in
				line with the requirements under Clause 14(a).</p>

			<p class=MsoNormal style='line-height:107%'><b>8.6</b>&nbsp;<b>Security of
					processing</b></p>

			<p class=MsoNormal style='line-height:107%'>(a) The data importer and, during
				transmission, also the data exporter shall implement appropriate technical and organizational
				measures to ensure the security of the data, including protection against a
				breach of security leading to accidental or unlawful destruction, loss,
				alteration, unauthorized disclosure or access to that data (hereinafter
				"personal data breach"). In assessing the appropriate level of security, the
				Parties shall take due account of the state of the art, the costs of
				implementation, the nature, scope, context and purpose(s) of processing and the
				risks involved in the processing for the data subjects. The Parties shall in
				particular consider having recourse to encryption or pseudonymization,
				including during transmission, where the purpose of processing can be fulfilled
				in that manner. In case of pseudonymization, the additional information for
				attributing the personal data to a specific data subject shall, where possible,
				remain under the exclusive control of the data exporter. In complying with its
				obligations under this paragraph, the data importer shall at least implement
				the technical and organizational measures specified in Annex II. The data
				importer shall carry out regular checks to ensure that these measures continue
				to provide an appropriate level of security.</p>

			<p class=MsoNormal style='line-height:107%'>(b) The data importer shall grant
				access to the personal data to members of its personnel only to the extent
				strictly necessary for the implementation, management, and monitoring of the
				contract. It shall ensure that persons authorized to process the personal data
				have committed themselves to confidentiality or are under an appropriate
				statutory obligation of confidentiality.</p>

			<p class=MsoNormal style='line-height:107%'>(c) In the event of a personal data
				breach concerning personal data processed by the data importer under these
				Clauses, the data importer shall take appropriate measures to address the
				breach, including measures to mitigate its adverse effects. The data importer
				shall also notify the data exporter without undue delay after having become
				aware of the breach. Such notification shall contain the details of a contact
				point where more information can be obtained, a description of the nature of
				the breach (including, where possible, categories and approximate number of
				data subjects and personal data records concerned), its likely consequences and
				the measures taken or proposed to address the breach including, where
				appropriate, measures to mitigate its possible adverse effects. Where, and in
				so far as, it is not possible to provide all information at the same time, the
				initial notification shall contain the information then available and further
				information shall, as it becomes available, subsequently be provided without
				undue delay.</p>

			<p class=MsoNormal style='line-height:107%'>(d) The data importer shall
				cooperate with and assist the data exporter to enable the data exporter to
				comply with its obligations under Regulation (EU) 2016/679, in particular to
				notify the competent supervisory authority and the affected data subjects,
				taking into account the nature of processing and the information available to
				the data importer.</p>

			<p class=MsoNormal style='line-height:107%'>&nbsp;</p>

			<p class=MsoNormal style='line-height:107%'><b>8.7</b>&nbsp;<b>Sensitive data</b></p>

			<p class=MsoNormal style='line-height:107%'>Where the transfer involves
				personal data revealing racial or ethnic origin, political opinions, religious
				or philosophical beliefs, or trade union membership, genetic data, or biometric
				data for the purpose of uniquely identifying a natural person, data concerning
				health or a person`s sex life or sexual orientation, or data relating to
				criminal convictions and offences (hereinafter "sensitive data"), the data
				importer shall apply the specific restrictions and/or additional safeguards
				described in Annex I.B.</p>

			<p class=MsoNormal style='line-height:107%'><b>8.8</b>&nbsp;<b>Onward transfers</b></p>

			<p class=MsoNormal style='line-height:107%'>The data importer shall only
				disclose the personal data to a third party on documented instructions from the
				data exporter. In addition, the data may only be disclosed to a third party
				located outside the European Union&nbsp; (in the same country as the data
				importer or in another third country, hereinafter "onward transfer") if the
				third party is or agrees to be bound by these Clauses, under the appropriate
				Module, or if:</p>

			<p class=MsoNormal style='line-height:107%'>(i) the onward transfer is to a
				country benefitting from an adequacy decision pursuant to Article 45 of
				Regulation (EU) 2016/679 that covers the onward transfer;</p>

			<p class=MsoNormal style='line-height:107%'>(ii) the third party otherwise
				ensures appropriate safeguards pursuant to Articles 46 or 47 Regulation of (EU)
				2016/679 with respect to the processing in question;</p>

			<p class=MsoNormal style='line-height:107%'>(iii) the onward transfer is
				necessary for the establishment, exercise or defense of legal claims in the
				context of specific administrative, regulatory or judicial proceedings; or</p>

			<p class=MsoNormal style='line-height:107%'>(iv) the onward transfer is
				necessary in order to protect the vital interests of the data subject or of
				another natural person.</p>

			<p class=MsoNormal style='line-height:107%'>Any onward transfer is subject to
				compliance by the data importer with all the other safeguards under these
				Clauses, in particular purpose limitation.&nbsp;</p>

			<p class=MsoNormal style='line-height:107%'><b>8.9</b>&nbsp;<b>Documentation
					and compliance</b></p>

			<p class=MsoNormal style='line-height:107%'>(a) The data importer shall
				promptly and adequately deal with enquiries from the data exporter that relate
				to the processing under these Clauses.</p>

			<p class=MsoNormal style='line-height:107%'>(b) The Parties shall be able to
				demonstrate compliance with these Clauses. In particular, the data importer
				shall keep appropriate documentation on the processing activities carried out
				on behalf of the data exporter.</p>

			<p class=MsoNormal style='line-height:107%'>(c) The data importer shall make
				available to the data exporter all information necessary to demonstrate
				compliance with the obligations set out in these Clauses and at the data
				exporter`s request, allow for and contribute to audits of the processing
				activities covered by these Clauses, at reasonable intervals or if there are
				indications of non-compliance. In deciding on a review or audit, the data
				exporter may take into account relevant certifications held by the data
				importer.</p>

			<p class=MsoNormal style='line-height:107%'>(d) The data exporter may choose to
				conduct the audit by itself or mandate an independent auditor. Audits may
				include inspections at the premises or physical facilities of the data importer
				and shall, where appropriate, be carried out with reasonable notice.</p>

			<p class=MsoNormal style='line-height:107%'>(e) The Parties shall make the
				information referred to in paragraphs (b) and (c), including the results of any
				audits, available to the competent supervisory authority on request.</p>

			<p class=MsoNormal style='line-height:107%'><i>Clause 9</i></p>

			<p class=MsoNormal style='line-height:107%'><b><i>Use of sub-processors</i></b></p>

			<p class=MsoNormal style='line-height:107%'>(a) The data importer has the data
				exporter`s general authorization for the engagement of sub-processor(s) from an
				agreed list. The data importer shall specifically inform the data exporter in
				writing of any intended changes to that list through the addition or
				replacement of sub-processors at least 30 business days in advance, thereby
				giving the data exporter sufficient time to be able to object to such changes
				prior to the engagement of the sub-processor(s). The data importer shall provide
				the data exporter with the information necessary to enable the data exporter to
				exercise its right to object.</p>

			<p class=MsoNormal style='line-height:107%'>(b) Where the data importer engages
				a sub-processor to carry out specific processing activities (on behalf of the
				data exporter), it shall do so by way of a written contract that provides for,
				in substance, the same data protection obligations as those binding the data
				importer under these Clauses, including in terms of third-party beneficiary
				rights for data subjects.&nbsp; The Parties agree that, by complying with this
				Clause, the data importer fulfils its obligations under Clause 8.8. The data
				importer shall ensure that the sub-processor complies with the obligations to
				which the data importer is subject pursuant to these Clauses.</p>

			<p class=MsoNormal style='line-height:107%'>(c) The data importer shall
				provide, at the data exporter`s request, a copy of such a sub-processor
				agreement and any subsequent amendments to the data exporter. To the extent
				necessary to protect business secrets or other confidential information,
				including personal data, the data importer may redact the text of the agreement
				prior to sharing a copy.</p>

			<p class=MsoNormal style='line-height:107%'>(d) The data importer shall remain
				fully responsible to the data exporter for the performance of the
				sub-processor`s obligations under its contract with the data importer. The data
				importer shall notify the data exporter of any failure by the sub-processor to
				fulfil its obligations under that contract.</p>

			<p class=MsoNormal style='line-height:107%'>(e) The data importer shall agree a
				third-party beneficiary clause with the sub-processor whereby - in the event
				the data importer has factually disappeared, ceased to exist in law or has
				become insolvent - the data exporter shall have the right to terminate the
				sub-processor contract and to instruct the sub-processor to erase or return the
				personal data.</p>

			<p class=MsoNormal style='line-height:107%'><i>Clause 10</i></p>

			<p class=MsoNormal style='line-height:107%'><b><i>Data subject rights</i></b></p>

			<p class=MsoNormal style='line-height:107%'>(a) The data importer shall
				promptly notify the data exporter of any request it has received from a data
				subject. It shall not respond to that request itself unless it has been authorized
				to do so by the data exporter.</p>

			<p class=MsoNormal style='line-height:107%'>(b) The data importer shall assist
				the data exporter in fulfilling its obligations to respond to data subjects,
				requests for the exercise of their rights under Regulation (EU) 2016/679. In
				this regard, the Parties shall set out in Annex II the appropriate technical
				and organizational measures, taking into account the nature of the processing,
				by which the assistance shall be provided, as well as the scope and the extent
				of the assistance required.</p>

			<p class=MsoNormal style='line-height:107%'>(c) In fulfilling its obligations
				under paragraphs (a) and (b), the data importer shall comply with the
				instructions from the data exporter.</p>

			<p class=MsoNormal style='line-height:107%'><i>Clause 11</i></p>

			<p class=MsoNormal style='line-height:107%'><b><i>Redress</i></b></p>

			<p class=MsoNormal style='line-height:107%'>(a) The data importer shall inform
				data subjects in a transparent and easily accessible format, through individual
				notice or on its website, of a contact point authorized to handle complaints.
				It shall deal promptly with any complaints it receives from a data
				subject.&nbsp;</p>

			<p class=MsoNormal style='line-height:107%'>(b) In case of a dispute between a
				data subject and one of the Parties as regards compliance with these Clauses,
				that Party shall use its best efforts to resolve the issue amicably in a timely
				fashion. The Parties shall keep each other informed about such disputes and,
				where appropriate, cooperate in resolving them.</p>

			<p class=MsoNormal style='line-height:107%'>(c) Where the data subject invokes
				a third-party beneficiary right pursuant to Clause 3, the data importer shall
				accept the decision of the data subject to:</p>

			<p class=MsoNormal style='line-height:107%'>(i) lodge a complaint with the
				supervisory authority in the Member State of his/her habitual residence or
				place of work, or the competent supervisory authority pursuant to Clause 13;</p>

			<p class=MsoNormal style='line-height:107%'>(ii) refer the dispute to the
				competent courts within the meaning of Clause 18.</p>

			<p class=MsoNormal style='line-height:107%'>(d) The Parties accept that the
				data subject may be represented by a not-for-profit body, organization or
				association under the conditions set out in Article 80(1) of Regulation (EU)
				2016/679.</p>

			<p class=MsoNormal style='line-height:107%'>(e) The data importer shall abide
				by a decision that is binding under the applicable EU or Member State law.</p>

			<p class=MsoNormal style='line-height:107%'>(f) The data importer agrees that
				the choice made by the data subject will not prejudice his/her substantive and
				procedural rights to seek remedies in accordance with applicable laws.</p>

			<p class=MsoNormal style='line-height:107%'><i>Clause 12</i></p>

			<p class=MsoNormal style='line-height:107%'><b><i>Liability</i></b></p>

			<p class=MsoNormal style='line-height:107%'>(a) Each Party shall be liable to
				the other Party/ies for any damages it causes the other Party/ies by any breach
				of these Clauses.</p>

			<p class=MsoNormal style='line-height:107%'>(b) The data importer shall be
				liable to the data subject, and the data subject shall be entitled to receive
				compensation, for any material or non-material damages the data importer or its
				sub-processor causes the data subject by breaching the third-party beneficiary
				rights under these Clauses.</p>

			<p class=MsoNormal style='line-height:107%'>(c) Notwithstanding paragraph (b),
				the data exporter shall be liable to the data subject, and the data subject
				shall be entitled to receive compensation, for any material or non-material
				damages the data exporter or the data importer (or its sub-processor) causes
				the data subject by breaching the third-party beneficiary rights under these
				Clauses. This is without prejudice to the liability of the data exporter and,
				where the data exporter is a processor acting on behalf of a controller, to the
				liability of the controller under Regulation (EU) 2016/679 or Regulation (EU)
				2018/1725, as applicable.</p>

			<p class=MsoNormal style='line-height:107%'>(d) The Parties agree that if the
				data exporter is held liable under paragraph (c) for damages caused by the data
				importer (or its sub-processor), it shall be entitled to claim back from the
				data importer that part of the compensation corresponding to the data importer`s
				responsibility for the damage.</p>

			<p class=MsoNormal style='line-height:107%'>(e) Where more than one Party is
				responsible for any damage caused to the data subject as a result of a breach
				of these Clauses, all responsible Parties shall be jointly and severally liable
				and the data subject is entitled to bring an action in court against any of
				these Parties.</p>

			<p class=MsoNormal style='line-height:107%'>(f) The Parties agree that if one
				Party is held liable under paragraph (e), it shall be entitled to claim back
				from the other Party/ies that part of the compensation corresponding to its /
				their responsibility for the damage.</p>

			<p class=MsoNormal style='line-height:107%'>(g) The data importer may not
				invoke the conduct of a sub-processor to avoid its own liability.</p>

			<p class=MsoNormal style='line-height:107%'><i>Clause 13</i></p>

			<p class=MsoNormal style='line-height:107%'><b><i>Supervision</i></b></p>

			<p class=MsoNormal style='line-height:107%'>(a) The supervisory authority with
				responsibility for ensuring compliance by the data exporter with Regulation
				(EU) 2016/679 as regards the data transfer, as indicated in Annex I.C, shall
				act as competent supervisory authority.</p>

			<p class=MsoNormal style='line-height:107%'>(b) The data importer agrees to
				submit itself to the jurisdiction of and cooperate with the competent
				supervisory authority in any procedures aimed at ensuring compliance with these
				Clauses. In particular, the data importer agrees to respond to enquiries,
				submit to audits and comply with the measures adopted by the supervisory
				authority, including remedial and compensatory measures. It shall provide the
				supervisory authority with written confirmation that the necessary actions have
				been taken.</p>

			<p class=MsoNormal style='line-height:107%'><b><u>SECTION III - LOCAL LAWS AND
						OBLIGATIONS IN CASE OF ACCESS BY<br>
					</u></b><b><u>PUBLIC AUTHORITIES</u></b></p>

			<p class=MsoNormal style='line-height:107%'><i>Clause 14</i></p>

			<p class=MsoNormal style='line-height:107%'><b><i>Local laws and practices
						affecting compliance with the Clauses</i></b></p>

			<p class=MsoNormal style='line-height:107%'>(a) The Parties warrant that they
				have no reason to believe that the laws and practices in the third country of
				destination applicable to the processing of the personal data by the data
				importer, including any requirements to disclose personal data or measures authorizing
				access by public authorities, prevent the data importer from fulfilling its
				obligations under these Clauses. This is based on the understanding that laws
				and practices that respect the essence of the fundamental rights and freedoms
				and do not exceed what is necessary and proportionate in a democratic society
				to safeguard one of the objectives listed in Article 23(1) of Regulation (EU)
				2016/679, are not in contradiction with these Clauses.</p>

			<p class=MsoNormal style='line-height:107%'>(b) The Parties declare that in
				providing the warranty in paragraph (a), they have taken due account in
				particular of the following elements:</p>

			<p class=MsoNormal style='line-height:107%'>(i) the specific circumstances of
				the transfer, including the length of the processing chain, the number of
				actors involved and the transmission channels used; intended onward transfers;
				the type of recipient; the purpose of processing; the categories and format of
				the transferred personal data; the economic sector in which the transfer
				occurs; the storage location of the data transferred;</p>

			<p class=MsoNormal style='line-height:107%'>(ii) the laws and practices of the
				third country of destination- including those requiring the disclosure of data
				to public authorities or authorizing access by such authorities - relevant in
				light of the specific circumstances of the transfer, and the applicable
				limitations and safeguards;</p>

			<p class=MsoNormal style='line-height:107%'>(iii) any relevant contractual,
				technical or organizational safeguards put in place to supplement the
				safeguards under these Clauses, including measures applied during transmission
				and to the processing of the personal data in the country of destination.</p>

			<p class=MsoNormal style='line-height:107%'>(c) The data importer warrants
				that, in carrying out the assessment under paragraph (b), it has made its best
				efforts to provide the data exporter with relevant information and agrees that
				it will continue to cooperate with the data exporter in ensuring compliance
				with these Clauses.</p>

			<p class=MsoNormal style='line-height:107%'>(d) The Parties agree to document
				the assessment under paragraph (b) and make it available to the competent
				supervisory authority on request.</p>

			<p class=MsoNormal style='line-height:107%'>(e) The data importer agrees to
				notify the data exporter promptly if, after having agreed to these Clauses and
				for the duration of the contract, it has reason to believe that it is or has
				become subject to laws or practices not in line with the requirements under
				paragraph (a), including following a change in the laws of the third country or
				a measure (such as a disclosure request) indicating an application of such laws
				in practice that is not in line with the requirements in paragraph (a).&nbsp;</p>

			<p class=MsoNormal style='line-height:107%'>(f) Following a notification
				pursuant to paragraph (e), or if the data exporter otherwise has reason to
				believe that the data importer can no longer fulfil its obligations under these
				Clauses, the data exporter shall promptly identify appropriate measures (e.g.
				technical or organizational measures to ensure security and confidentiality) to
				be adopted by the data exporter and/or data importer to address the situation.
				The data exporter shall suspend the data transfer if it considers that no
				appropriate safeguards for such transfer can be ensured, or if instructed by
				the competent supervisory authority to do so. In this case, the data exporter
				shall be entitled to terminate the contract, insofar as it concerns the
				processing of personal data under these Clauses. If the contract involves more
				than two Parties, the data exporter may exercise this right to termination only
				with respect to the relevant Party, unless the Parties have agreed otherwise.
				Where the contract is terminated pursuant to this Clause, Clause 16(d) and (e)
				shall apply.</p>

			<p class=MsoNormal style='line-height:107%'><i>Clause 15</i></p>

			<p class=MsoNormal style='line-height:107%'><b><i>Obligations of the data
						importer in case of access by public authorities</i></b></p>

			<p class=MsoNormal style='line-height:107%'><b>15.1</b>&nbsp;<b>Notification</b></p>

			<p class=MsoNormal style='line-height:107%'>(a) The data importer agrees to notify
				the data exporter and, where possible, the data subject promptly (if necessary
				with the help of the data exporter) if it:</p>

			<p class=MsoNormal style='line-height:107%'>(i) receives a legally binding
				request from a public authority, including judicial authorities, under the laws
				of the country of destination for the disclosure of personal data transferred
				pursuant to these Clauses; such notification shall include information about
				the personal data requested, the requesting authority, the legal basis for the
				request and the response provided; or</p>

			<p class=MsoNormal style='line-height:107%'>(ii) becomes aware of any direct
				access by public authorities to personal data transferred pursuant to these
				Clauses in accordance with the laws of the country of destination; such
				notification shall include all information available to the importer.</p>

			<p class=MsoNormal style='line-height:107%'>(b) If the data importer is
				prohibited from notifying the data exporter and/or the data subject under the
				laws of the country of destination, the data importer agrees to use its best
				efforts to obtain a waiver of the prohibition, with a view to communicating as
				much information as possible, as soon as possible. The data importer agrees to
				document its best efforts in order to be able to demonstrate them on request of
				the data exporter.</p>

			<p class=MsoNormal style='line-height:107%'>(c) Where permissible under the
				laws of the country of destination, the data importer agrees to provide the
				data exporter, at regular intervals for the duration of the contract, with as
				much relevant information as possible on the requests received (in particular,
				number of requests, type of data requested, requesting authorities, whether
				requests have been challenged and the outcome of such challenges, etc.).&nbsp;</p>

			<p class=MsoNormal style='line-height:107%'>(d) The data importer agrees to
				preserve the information pursuant to paragraphs (a) to (c) for the duration of
				the contract and make it available to the competent supervisory authority on
				request.</p>

			<p class=MsoNormal style='line-height:107%'>(e) Paragraphs (a) to (c) are
				without prejudice to the obligation of the data importer pursuant to Clause
				14(e) and Clause 16 to inform the data exporter promptly where it is unable to
				comply with these Clauses.</p>

			<p class=MsoNormal style='line-height:107%'><b>15.2</b>&nbsp;<b>Review of
					legality and data minimization</b></p>

			<p class=MsoNormal style='line-height:107%'>(a) The data importer agrees to
				review the legality of the request for disclosure, in particular whether it
				remains within the powers granted to the requesting public authority, and to
				challenge the request if, after careful assessment, it concludes that there are
				reasonable grounds to consider that the request is unlawful under the laws of
				the country of destination, applicable obligations under international law and
				principles of international comity. The data importer shall, under the same
				conditions, pursue possibilities of appeal. When challenging a request, the
				data importer shall seek interim measures with a view to suspending the effects
				of the request until the competent judicial authority has decided on its
				merits. It shall not disclose the personal data requested until required to do
				so under the applicable procedural rules. These requirements are without
				prejudice to the obligations of the data importer under Clause 14(e).</p>

			<p class=MsoNormal style='line-height:107%'>(b) The data importer agrees to
				document its legal assessment and any challenge to the request for disclosure
				and, to the extent permissible under the laws of the country of destination,
				make the documentation available to the data exporter. It shall also make it
				available to the competent supervisory authority on request.&nbsp;</p>

			<p class=MsoNormal style='line-height:107%'>(c) The data importer agrees to
				provide the minimum amount of information permissible when responding to a
				request for disclosure, based on a reasonable interpretation of the request.</p>

			<p class=MsoNormal style='line-height:107%'><b><u>SECTION IV - FINAL PROVISIONS</u></b></p>

			<p class=MsoNormal style='line-height:107%'><i>Clause 16</i></p>

			<p class=MsoNormal style='line-height:107%'><b><i>Non-compliance with the
						Clauses and termination</i></b></p>

			<p class=MsoNormal style='line-height:107%'>(a)&nbsp; &nbsp; The data importer
				shall promptly inform the data exporter if it is unable to comply with these
				Clauses, for whatever reason.</p>

			<p class=MsoNormal style='line-height:107%'>(b) In the event that the data
				importer is in breach of these Clauses or unable to comply with these Clauses,
				the data exporter shall suspend the transfer of personal data to the data
				importer until compliance is again ensured or the contract is terminated. This
				is without prejudice to Clause 14(f).</p>

			<p class=MsoNormal style='line-height:107%'>(c) The data exporter shall be
				entitled to terminate the contract, insofar as it concerns the processing of
				personal data under these Clauses, where:</p>

			<p class=MsoNormal style='line-height:107%'>(i) the data exporter has suspended
				the transfer of personal data to the data importer pursuant to paragraph (b)
				and compliance with these Clauses is not restored within a reasonable time and
				in any event within one month of suspension;</p>

			<p class=MsoNormal style='line-height:107%'>(ii) the data importer is in
				substantial or persistent breach of these Clauses; or</p>

			<p class=MsoNormal style='line-height:107%'>(iii) the data importer fails to
				comply with a binding decision of a competent court or supervisory authority
				regarding its obligations under these Clauses.</p>

			<p class=MsoNormal style='line-height:107%'>In these cases, it shall inform the
				competent supervisory authority such non-compliance. Where the contract
				involves more than two Parties, the data exporter may exercise this right to
				termination only with respect to the relevant Party, unless the Parties have
				agreed otherwise.</p>

			<p class=MsoNormal style='line-height:107%'>(d) Personal data that has been
				transferred prior to the termination of the contract pursuant to paragraph (c)
				shall at the choice of the data exporter immediately be returned to the data
				exporter or deleted in its entirety. The same shall apply to any copies of the
				data. The data importer shall certify the deletion of the data to the data
				exporter. Until the data is deleted or returned, the data importer shall
				continue to ensure compliance with these Clauses. In case of local laws
				applicable to the data importer that prohibit the return or deletion of the
				transferred personal data, the data importer warrants that it will continue to
				ensure compliance with these Clauses and will only process the data to the
				extent and for as long as required under that local law.</p>

			<p class=MsoNormal style='line-height:107%'>(e) Either Party may revoke its
				agreement to be bound by these Clauses where (i) the European Commission adopts
				a decision pursuant to Article 45(3) of Regulation (EU) 2016/679 that covers
				the transfer of personal data to which these Clauses apply; or (ii) Regulation
				(EU) 2016/679 becomes part of the legal framework of the country to which the
				personal data is transferred. This is without prejudice to other obligations
				applying to the processing in question under Regulation (EU) 2016/679.</p>

			<p class=MsoNormal style='line-height:107%'><i>Clause 17</i></p>

			<p class=MsoNormal style='line-height:107%'><b><i>Governing law</i></b></p>

			<p class=MsoNormal style='line-height:107%'>These Clauses shall be governed by
				the law of one of the EU Member States, provided such law allows for
				third-party beneficiary rights. The Parties agree that these Clauses shall be
				governed in accordance with the 'Contracting Entity; Applicable Law; Notice
				section of the Jurisdiction Specific Terms or if such section does not specify
				an EU Member State, by the law of the Republic of Ireland (without reference to
				conflicts of law principles)</p>

			<p class=MsoNormal style='line-height:107%'><i>Clause 18</i></p>

			<p class=MsoNormal style='line-height:107%'><b><i>Choice of forum and
						jurisdiction</i></b></p>

			<p class=MsoNormal style='line-height:107%'>(a) Any dispute arising from these
				Clauses shall be resolved by the courts of an EU Member State.</p>

			<p class=MsoNormal style='line-height:107%'>(b) The Parties agree that those
				shall be the courts of the jurisdiction specified in Clause 17.</p>

			<p class=MsoNormal style='line-height:107%'>(c) A data subject may also bring
				legal proceedings against the data exporter and/or data importer before the
				courts of the Member State in which he/she has his/her habitual residence.</p>

			<p class=MsoNormal style='line-height:107%'>(d) The Parties agree to submit
				themselves to the jurisdiction of such courts.</p>

			<p class=MsoNormal style='line-height:115%'>&nbsp;</p>

			<p class=MsoNormal style='line-height:115%'><b>On behalf of the data exporter:</b></p>

			<p class=MsoNormal>Name (written out in full): {{ customer.name }}</p>

			<p class=MsoNormal>Company: {{ customer.company }}</p>

			<p class=MsoNormal>Position: {{ customer.title }}</p>

			<p class=MsoNormal>Address: {{ customer.name }}</p>

			<p class=MsoNormal style='line-height:200%'>Other information necessary in
				order for the contract to be binding (if any): </p>

			<p class=MsoNormal>Signature: </p>

			<img v-if="customer.signature != ''" id="signatureImg" class="signature" :src="customer.signature"
				alt="signature">

			<p class=MsoNormal style='line-height:115%'>&nbsp;</p>

			<p class=MsoNormal style='line-height:115%'><b>On behalf of the data importer:</b></p>

			<p class=MsoNormal style='line-height:200%'>Name (written out in full):
				Francesco Montanari</p>

			<p class=MsoNormal style='line-height:200%'>Position: CTO &amp; Data Protection
				Officer</p>

			<p class=MsoNormal style='line-height:200%'>Address: Mihail Kogalniceanu 17b,
				bloc C4 , city Brasov, Romania</p>

			<p class=MsoNormal style='line-height:200%'>&nbsp;</p>

			<p class=MsoNormal>Signature: </p>

			<p class=MsoNormal>
				<img src="signature.png" alt="signature" width="300">
			</p>

			<p class=MsoNormal style='line-height:115%'>&nbsp;</p>

			<p class=MsoNormal style='margin-left:288.0pt;line-height:115%'>&nbsp;</p>

			<b><span style='font-size:10.5pt;line-height:115%;font-family:"Calibri",sans-serif'><br clear=all
						style='page-break-before:always'>
				</span></b>

			<p class=MsoNormal align=center style='text-align:center;line-height:115%'><b>Annex
					1 to the Standard Contractual Clauses</b></p>

			<p class=MsoNormal style='line-height:115%'>This Appendix forms part of the
				Standard Contractual Clauses.</p>

			<p class=MsoNormal style='line-height:115%'>A description of the Details of
				Processing, including (i) List of Parties, (ii) Description of the </p>

			<p class=MsoNormal style='line-height:115%'>Transfer and (iii) Competent
				Supervisory Authority are set out in Annex 1 of the DPA.</p>

			<p class=MsoNormal style='line-height:200%'><b>&nbsp;</b></p>

			<p class=MsoNormal style='line-height:200%'><b>DATA EXPORTER</b></p>

			<p class=MsoNormal style='line-height:200%'>Name (written out in full): {{ customer.name }}</p>

			<p class=MsoNormal style='line-height:200%'>Company: {{ customer.company }}</p>

			<p class=MsoNormal style='line-height:200%'>Position: {{ customer.title }}</p>

			<p class=MsoNormal style='line-height:200%'>Address: {{ customer.address }}</p>

			<p class=MsoNormal>Authorized Signature: </p>

			<img v-if="customer.signature != ''" id="signatureImg" class="signature" :src="customer.signature"
				alt="signature">

			<p class=MsoNormal style='line-height:200%'>&nbsp;</p>

			<p class=MsoNormal style='line-height:200%'><b><span lang=IT>DATA IMPORTER</span></b></p>

			<p class=MsoNormal style='line-height:200%'><span lang=IT>Name: Francesco
					Montanari</span></p>

			<p class=MsoNormal style='line-height:200%'><span lang=IT>Position: </span>CTO
				&amp; Data Protection Officer</p>

			<p class=MsoNormal>Authorized Signature: </p>

			<p class=MsoNormal>
				<img src="signature.png" alt="signature" width="300">
			</p>

			<p class=MsoNormal style='line-height:200%'>&nbsp;</p>

			<p class=MsoNormal align=center style='text-align:center;line-height:115%'><b>&nbsp;</b></p>

			<p class=MsoNormal align=center style='text-align:center;line-height:115%'><b>&nbsp;</b></p>

			<p class=MsoNormal align=center style='text-align:center;line-height:115%'><b>&nbsp;</b></p>

			<p class=MsoNormal align=center style='text-align:center;line-height:115%'><b>&nbsp;</b></p>

			<p class=MsoNormal align=center style='text-align:center;line-height:115%'><b>&nbsp;</b></p>

			<p class=MsoNormal align=center style='text-align:center;line-height:115%'><b>Annex
					2 to the Standard Contractual Clauses</b></p>

			<p class=MsoNormal align=center style='text-align:center;line-height:115%'><b>&nbsp;</b></p>

			<p class=MsoNormal style='line-height:115%'>This Appendix forms part of the Standard
				Contractual Clauses.</p>

			<p class=MsoNormal style='line-height:115%'>A description of the technical and organizational
				security measures implemented by the data importer in accordance with Standard
				Contractual Clauses are set out in Annex 2 of the DPA.</p>

			<p class=MsoNormal style='line-height:115%'>&nbsp;</p>

			<p class=MsoNormal style='line-height:200%'><b>DATA EXPORTER</b></p>

			<p class=MsoNormal style='line-height:200%'>Name (written out in full): {{ customer.name }}</p>

			<p class=MsoNormal style='line-height:200%'>Company: {{ customer.company }}</p>

			<p class=MsoNormal style='line-height:200%'>Position: {{ customer.title }}</p>

			<p class=MsoNormal style='line-height:200%'>Address: {{ customer.address }}</p>

			<p class=MsoNormal>Authorized Signature: </p>

			<img v-if="customer.signature != ''" id="signatureImg" class="signature" :src="customer.signature"
				alt="signature">

			<p class=MsoNormal style='line-height:115%'>&nbsp;</p>

			<p class=MsoNormal style='line-height:200%'><b><span lang=IT>DATA IMPORTER</span></b></p>

			<p class=MsoNormal style='line-height:200%'>Name: Francesco Montanari</p>

			<p class=MsoNormal style='line-height:200%'><span lang=IT>Position: </span>CTO
				&amp; Data Protection Officer</p>

			<p class=MsoNormal>Authorized Signature: </p>

			<p class=MsoNormal>
				<img src="signature.png" alt="signature" width="300">
			</p>

			<p class=MsoNormal style='line-height:200%'>&nbsp;</p>

			<span style='font-size:10.5pt;line-height:115%;font-family:"Calibri",sans-serif'><br clear=all
					style='page-break-before:always'>
			</span>

			<p class=MsoNormal align=center style='text-align:center;line-height:115%'><b>Annex
					3 to the Standard Contractual Clauses</b></p>

			<p class=MsoNormal align=center style='text-align:center;line-height:115%'><b>&nbsp;</b></p>

			<p class=MsoNormal style='line-height:115%'>This Appendix forms part of the
				Standard Contractual Clauses.</p>

			<p class=MsoNormal style='line-height:115%'>The List of Sub-Processors used by
				the data importer are listed in accordance with Clause 9(a) of the Standard
				Contractual Clauses are set out in Annex 2 of the DPA:</p>

			<p class=MsoNormal style='line-height:115%'>&nbsp;</p>

			<p class=MsoNormal style='line-height:200%'><b>DATA EXPORTER</b></p>

			<p class=MsoNormal style='line-height:200%'>Name (written out in full): {{ customer.name }}</p>

			<p class=MsoNormal style='line-height:200%'>Company: {{ customer.company }}</p>

			<p class=MsoNormal style='line-height:200%'>Position: {{ customer.position }}</p>

			<p class=MsoNormal style='line-height:300%'>Address: {{ customer.address }}</p>

			<p class=MsoNormal style='line-height:300%'>Authorized Signature: </p>

			<img v-if="customer.signature != ''" id="signatureImg" class="signature" :src="customer.signature"
				alt="signature">

			<p class=MsoNormal style='line-height:200%'><b><span lang=IT>DATA IMPORTER</span></b></p>

			<p class=MsoNormal style='line-height:200%'>Name: Francesco Montanari</p>

			<p class=MsoNormal style='line-height:200%'><span lang=IT>Position: </span>CTO
				&amp; Data Protection Officer</p>

			<p class=MsoNormal>Authorized Signature: </p>

			<p class=MsoNormal>
				<img src="signature.png" alt="signature" width="300">
			</p>

			<p class=MsoNormal style='line-height:200%'></p>

			<p class=MsoNormal style='line-height:200%'>&nbsp;</p>

			<p class=MsoNormal style='line-height:200%'>&nbsp;</p>

			<p class=MsoNormal style='line-height:200%'>&nbsp;</p>

			<p class=MsoNormal style='line-height:200%'>&nbsp;</p>

			<p class=MsoNormal align=center style='text-align:center;line-height:107%'><b><u><span
							style='text-decoration:none'>&nbsp;</span></u></b></p>

			<p class=MsoNormal align=center style='text-align:center;line-height:107%'><b><u>UK
						AND SWISS ADDENDUM TO THE STANDARD CONTRACTUAL CLAUSES</u></b></p>

			<p class=MsoNormal style='line-height:150%'>(a) This Addendum amends the
				Standard Contractual Clauses to the extent necessary so they operate for
				transfers made by the data exporter to the data importer, to the extent that
				the UK GDPR or Swiss DPA (as defined in the HubSpot Data Processing Addendum)
				apply to the data exporter`s processing when making that transfer.</p>

			<p class=MsoNormal style='line-height:150%'>(b) The Standard Contractual
				Clauses shall be amended with the following modifications:</p>

			<p class=MsoNormal style='line-height:150%'>(i) references to &quot;Regulation
				(EU) 2016/679&quot; shall be interpreted as references to the UK GDPR or Swiss
				DPA (as applicable);</p>

			<p class=MsoNormal style='line-height:150%'>(ii) references to specific
				Articles of &quot;Regulation (EU) 2016/679&quot; shall be replaced with the
				equivalent article or section of the UK GDPR or Swiss DPA (as applicable);</p>

			<p class=MsoNormal style='line-height:150%'>(iii) references to Regulation (EU)
				2018/1725 shall be removed;</p>

			<p class=MsoNormal style='line-height:150%'>(iv) references to &quot;EU&quot;,
				&quot;Union&quot; and &quot;Member State&quot; shall be replaced with
				references to the &quot;UK&quot; or &quot;Switzerland&quot; (as applicable);</p>

			<p class=MsoNormal style='line-height:150%'>(v) Clause 13(a) and Part C of
				Annex II are not used and the &quot;competent supervisory authority&quot; shall
				be the United Kingdom Information Commissioner or Swiss Federal Data Protection
				Information Commissioner (as applicable);</p>

			<p class=MsoNormal style='line-height:150%'>(vi) references to the
				&quot;competent supervisory authority&quot; and &quot;competent courts&quot;
				shall be replaced with references to the &quot;Information Commissioner&quot;
				and the &quot;courts of England and Wales&quot; or the &quot;Swiss Federal Data
				Protection Information Commissioner&quot; and &quot;applicable courts of
				Switzerland&quot; (as applicable);</p>

			<p class=MsoNormal style='line-height:150%'>(vii) in Clause 17, the Standard
				Contractual Clauses shall be governed by the laws of England and Wales or
				Switzerland (as applicable); and</p>

			<p class=MsoNormal style='line-height:150%'>(viii) to the extent the UK GDPR
				applies to the processing, Clause 18 shall be replaced to state: "Any dispute
				arising from these Clauses shall be resolved by the courts of England and
				Wales. A data subject may also bring legal proceedings against the data
				exporter and/or data importer before the courts of any country in the UK. The
				Parties agree to submit themselves to the jurisdiction of such courts"; and</p>

			<p class=MsoNormal style='line-height:150%'>(ix) to the extent the Swiss DPA
				applies to the processing, Clause 18 shall be replaced to state: "Any dispute
				arising from these Clauses shall be resolved by the competent courts of
				Switzerland. The Parties agree to submit themselves to the jurisdiction of such
				courts".</p>

			<p class=MsoNormal style='line-height:150%'>&nbsp;</p>

			<p class=MsoNormal style='line-height:200%'><b>DATA EXPORTER</b></p>

			<p class=MsoNormal style='line-height:200%'>Name (written out in full): {{ customer.name }}</p>

			<p class=MsoNormal style='line-height:200%'>Company: {{ customer.company }}</p>

			<p class=MsoNormal style='line-height:200%'>Position: {{ customer.title }}</p>

			<p class=MsoNormal style='line-height:300%'>Address: {{ customer.address }}</p>

			<p class=MsoNormal style='line-height:300%'>Authorized Signature: </p>

			<img v-if="customer.signature != ''" id="signatureImg" class="signature" :src="customer.signature"
				alt="signature">

			<p class=MsoNormal style='line-height:200%'><b><span lang=IT>DATA IMPORTER</span></b></p>

			<p class=MsoNormal style='line-height:200%'>Name: Francesco Montanari</p>

			<p class=MsoNormal style='line-height:200%'><span lang=IT>Position: </span>CTO
				&amp; Data Protection Officer</p>

			<p class=MsoNormal style='line-height:200%'>&nbsp;</p>

			<p class=MsoNormal>Authorized Signature: </p>

			<p class=MsoNormal>
				<img src="signature.png" alt="signature" width="300">
			</p>

			<p class=MsoNormal>&nbsp;</p>

			<p class=MsoNormal>&nbsp;</p>

		</div>

	</div>
</template>

<script>
/* eslint-disable */
export default {
	name: 'DPA_Template',
	props: {
		customer: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {}
	},
	created() {
		setTimeout(() => this.customer.signature = '', 1000)
	},
}
</script>

<style scoped>
/* eslint-disable */
.signature {
	width: 13vw;
	height: auto;
	display: flex;
	margin-left: 3%;
}

.mainSection {
	word-wrap:break-word; 
	border-radius: 5px; 
	line-height: 1.6; 
	letter-spacing: 1.2px; 
	word-spacing: 1.5px;
	padding-left: 30px;
	padding-right: 30px;
}

table {
	border-collapse: collapse;
	border: none;
	margin: auto;
	width: 100%;
}

@media screen and (max-width: 460px) {
	.signature {
		width: 23vw;
		height: auto;
		display: flex;
		margin-left: 10%;
	}

	table {
		border-collapse: collapse;
		margin: auto;
		border: none;
		width: auto;
	}

	.mainSection {
		word-wrap:break-word; 
		border-radius: 5px; 
		line-height: 1.6; 
		letter-spacing: 1.2px; 
		word-spacing: 1.5px;
		padding-left: 1px;
		padding-right: 1px;
	}
}

p.MsoNormal,
li.MsoNormal,
div.MsoNormal {
	margin-top: 0cm;
	margin-right: 0cm;
	margin-bottom: 8.0pt;
	margin-left: 0cm;
	line-height: 125%;
	font-size: 10.5pt;
	font-family: "Calibri", sans-serif;
	text-align: left;
}

h1 {
	margin-top: 16.0pt;
	margin-right: 0cm;
	margin-bottom: 4.0pt;
	margin-left: 0cm;
	text-align: center;
	page-break-after: avoid;
	font-size: 20.0pt;
	font-family: "Calibri Light", sans-serif;
	color: #2E74B5;
	font-weight: normal;
}

h2 {
	margin-top: 8.0pt;
	margin-right: 0cm;
	margin-bottom: 2.0pt;
	margin-left: 0cm;
	text-align: center;
	page-break-after: avoid;
	font-size: 16.0pt;
	font-family: "Calibri Light", sans-serif;
	font-weight: normal;
}

h3 {
	margin-top: 8.0pt;
	margin-right: 0cm;
	margin-bottom: 0cm;
	margin-left: 0cm;
	page-break-after: avoid;
	font-size: 16.0pt;
	font-family: "Calibri Light", sans-serif;
	font-weight: normal;
}

h4 {
	margin-top: 4.0pt;
	margin-right: 0cm;
	margin-bottom: 0cm;
	margin-left: 0cm;
	line-height: 125%;
	page-break-after: avoid;
	font-size: 15.0pt;
	font-family: "Calibri Light", sans-serif;
	font-weight: normal;
	font-style: italic;
}

h5 {
	margin-top: 2.0pt;
	margin-right: 0cm;
	margin-bottom: 0cm;
	margin-left: 0cm;
	line-height: 125%;
	page-break-after: avoid;
	font-size: 14.0pt;
	font-family: "Calibri Light", sans-serif;
	font-weight: normal;
}

h6 {
	margin-top: 2.0pt;
	margin-right: 0cm;
	margin-bottom: 0cm;
	margin-left: 0cm;
	line-height: 125%;
	page-break-after: avoid;
	font-size: 13.0pt;
	font-family: "Calibri Light", sans-serif;
	font-weight: normal;
	font-style: italic;
}

p.MsoHeading7,
li.MsoHeading7,
div.MsoHeading7 {
	margin-top: 2.0pt;
	margin-right: 0cm;
	margin-bottom: 0cm;
	margin-left: 0cm;
	line-height: 125%;
	page-break-after: avoid;
	font-size: 12.0pt;
	font-family: "Calibri Light", sans-serif;
}

p.MsoHeading8,
li.MsoHeading8,
div.MsoHeading8 {
	margin-top: 2.0pt;
	margin-right: 0cm;
	margin-bottom: 0cm;
	margin-left: 0cm;
	line-height: 125%;
	page-break-after: avoid;
	font-size: 11.0pt;
	font-family: "Calibri Light", sans-serif;
	font-style: italic;
}

p.MsoHeading9,
li.MsoHeading9,
div.MsoHeading9 {
	margin-top: 2.0pt;
	margin-right: 0cm;
	margin-bottom: 0cm;
	margin-left: 0cm;
	line-height: 125%;
	page-break-after: avoid;
	font-size: 10.5pt;
	font-family: "Calibri", sans-serif;
	font-weight: bold;
	font-style: italic;
}

p.MsoHeader,
li.MsoHeader,
div.MsoHeader {
	margin-top: 0cm;
	margin-right: 0cm;
	margin-bottom: 8.0pt;
	margin-left: 0cm;
	line-height: 125%;
	font-size: 10.5pt;
	font-family: "Calibri", sans-serif;
}

p.MsoFooter,
li.MsoFooter,
div.MsoFooter {
	margin-top: 0cm;
	margin-right: 0cm;
	margin-bottom: 8.0pt;
	margin-left: 0cm;
	line-height: 125%;
	font-size: 10.5pt;
	font-family: "Calibri", sans-serif;
}

p.MsoCaption,
li.MsoCaption,
div.MsoCaption {
	margin-top: 0cm;
	margin-right: 0cm;
	margin-bottom: 8.0pt;
	margin-left: 0cm;
	font-size: 8.0pt;
	font-family: "Calibri", sans-serif;
	color: #404040;
	font-weight: bold;
}

p.MsoTitle,
li.MsoTitle,
div.MsoTitle {
	margin-top: 0cm;
	margin-right: 0cm;
	margin-bottom: 20.0pt;
	margin-left: 0cm;
	text-align: center;
	border: none;
	padding: 0cm;
	font-size: 36.0pt;
	font-family: "Calibri Light", sans-serif;
	color: #44546A;
	text-transform: uppercase;
	letter-spacing: 1.5pt;
}

p.MsoTitleCxSpFirst,
li.MsoTitleCxSpFirst,
div.MsoTitleCxSpFirst {
	margin: 0cm;
	text-align: center;
	border: none;
	padding: 0cm;
	font-size: 36.0pt;
	font-family: "Calibri Light", sans-serif;
	color: #44546A;
	text-transform: uppercase;
	letter-spacing: 1.5pt;
}

p.MsoTitleCxSpMiddle,
li.MsoTitleCxSpMiddle,
div.MsoTitleCxSpMiddle {
	margin: 0cm;
	text-align: center;
	border: none;
	padding: 0cm;
	font-size: 36.0pt;
	font-family: "Calibri Light", sans-serif;
	color: #44546A;
	text-transform: uppercase;
	letter-spacing: 1.5pt;
}

p.MsoTitleCxSpLast,
li.MsoTitleCxSpLast,
div.MsoTitleCxSpLast {
	margin-top: 0cm;
	margin-right: 0cm;
	margin-bottom: 20.0pt;
	margin-left: 0cm;
	text-align: center;
	border: none;
	padding: 0cm;
	font-size: 36.0pt;
	font-family: "Calibri Light", sans-serif;
	color: #44546A;
	text-transform: uppercase;
	letter-spacing: 1.5pt;
}

p.MsoSubtitle,
li.MsoSubtitle,
div.MsoSubtitle {
	margin-top: 0cm;
	margin-right: 0cm;
	margin-bottom: 8.0pt;
	margin-left: 0cm;
	text-align: center;
	line-height: 125%;
	font-size: 14.0pt;
	font-family: "Calibri", sans-serif;
	color: #44546A;
}

a:link,
span.MsoHyperlink {
	font-family: "Times New Roman", serif;
	color: #0563C1;
	text-decoration: underline;
}

a:visited,
span.MsoHyperlinkFollowed {
	color: #954F72;
	text-decoration: underline;
}

strong {
	font-family: "Times New Roman", serif;
}

em {
	font-family: "Times New Roman", serif;
	color: black;
}

p.MsoNoSpacing,
li.MsoNoSpacing,
div.MsoNoSpacing {
	margin: 0cm;
	font-size: 10.5pt;
	font-family: "Calibri", sans-serif;
}

p.MsoQuote,
li.MsoQuote,
div.MsoQuote {
	margin-top: 8.0pt;
	margin-right: 36.0pt;
	margin-bottom: 8.0pt;
	margin-left: 36.0pt;
	text-align: center;
	line-height: 125%;
	font-size: 12.0pt;
	font-family: "Calibri", sans-serif;
	color: #7B7B7B;
	font-style: italic;
}

p.MsoIntenseQuote,
li.MsoIntenseQuote,
div.MsoIntenseQuote {
	margin-top: 8.0pt;
	margin-right: 46.8pt;
	margin-bottom: 8.0pt;
	margin-left: 46.8pt;
	text-align: center;
	line-height: 115%;
	font-size: 14.0pt;
	font-family: "Calibri Light", sans-serif;
	color: #2E74B5;
	text-transform: uppercase;
}

span.MsoSubtleEmphasis {
	font-family: "Times New Roman", serif;
	color: #595959;
	font-style: italic;
}

span.MsoIntenseEmphasis {
	font-family: "Times New Roman", serif;
	color: windowtext;
	font-weight: bold;
	font-style: italic;
}

span.MsoSubtleReference {
	font-family: "Times New Roman", serif;
	font-variant: small-caps;
	color: #404040;
	letter-spacing: 0pt;
	text-decoration: underline;
}

span.MsoIntenseReference {
	font-family: "Times New Roman", serif;
	font-variant: small-caps;
	color: windowtext;
	letter-spacing: 0pt;
	font-weight: bold;
	text-decoration: underline;
}

span.MsoBookTitle {
	font-family: "Times New Roman", serif;
	font-variant: small-caps;
	letter-spacing: 0pt;
	font-weight: bold;
}

p.MsoTocHeading,
li.MsoTocHeading,
div.MsoTocHeading {
	margin-top: 16.0pt;
	margin-right: 0cm;
	margin-bottom: 4.0pt;
	margin-left: 0cm;
	text-align: center;
	page-break-after: avoid;
	font-size: 20.0pt;
	font-family: "Calibri Light", sans-serif;
	color: #2E74B5;
}

span.Heading1Char {
	font-family: "Calibri Light", sans-serif;
	color: #2E74B5;
}

span.Heading2Char {
	font-family: "Calibri Light", sans-serif;
}

span.Heading3Char {
	font-family: "Calibri Light", sans-serif;
}

span.Heading4Char {
	font-family: "Calibri Light", sans-serif;
	font-style: italic;
}

span.Heading5Char {
	font-family: "Calibri Light", sans-serif;
}

span.Heading6Char {
	font-family: "Calibri Light", sans-serif;
	font-style: italic;
}

span.Heading7Char {
	font-family: "Calibri Light", sans-serif;
}

span.Heading8Char {
	font-family: "Calibri Light", sans-serif;
	font-style: italic;
}

span.Heading9Char {
	font-family: "Times New Roman", serif;
	font-weight: bold;
	font-style: italic;
}

span.HeaderChar {
	font-family: "Times New Roman", serif;
}

span.FooterChar {
	font-family: "Times New Roman", serif;
}

span.TitleChar {
	font-family: "Calibri Light", sans-serif;
	color: #44546A;
	text-transform: uppercase;
	letter-spacing: 1.5pt;
}

span.SubtitleChar {
	font-family: "Times New Roman", serif;
	color: #44546A;
}

span.QuoteChar {
	font-family: "Times New Roman", serif;
	color: #7B7B7B;
	font-style: italic;
}

span.IntenseQuoteChar {
	font-family: "Calibri Light", sans-serif;
	color: #2E74B5;
	text-transform: uppercase;
}

.MsoChpDefault {
	font-family: "Calibri", sans-serif;
}

ol {
	margin-bottom: 0cm;
}

ul {
	margin-bottom: 0cm;
}
</style>