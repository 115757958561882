<template>
  <!-- eslint-disable -->
   <div lang=EN-US link="#0563C1" vlink="#954F72" class="mainSection">

      <div class=WordSection1>

         <p class=MsoNormal align=center style='text-align:center;line-height:115%'><b><span
                  style='font-size:24.0pt;line-height:115%'>Client Agreement</span></b></p>
         <p class=MsoNormal style='line-height:115%'>&nbsp;</p>
         <p class="font-weight-bold">1. Parties</p>

         <p class=MsoNormal style='line-height:150%'>The Service provider, TRAFFIC MANAGER GROUP S.R.L. with registered
            address Str. Ionel Teodoreanu 1 nr. 1 bl. 1 ap. 1 500165 Brașov, Romania, VAT RO43058081 and registration
            number J08/1846/2020 under the laws of Romania</p>

         <p class=MsoNormal style='line-height:150%'>and The Client, User of the TrafficManager Tracking platform and all
            the services provided by Us thru the website TrafficManager.com (the “Website”) agreed the following:</p>

         <p class=MsoNormal style='line-height:150%'>By signing up (even on behalf of a Company) to use the Traffic Manager
            Tracking
            Platform
            including any other content or information provided to you as part of our Platform,
            Website, Software or Services you are entering into a legal agreement with Traffic
            Manager Limited (the platform Owner) which may conclude this agreement on its own
            behalf
            or can also be represented by Traffic Manager Group S.R.L. or other 3rd party
            entities
            who have the right to provide our Clients access to the platform via our Website and
            collect the fees form the Client ("We", "Us", "TrafficManager").</p>

         <p class=MsoNormal style='line-height:150%'>You and we should be known collectively as the "Parties" and
            individually as a
            "Party".</p>

         <p class=MsoNormal style='line-height:150%'>This Client Agreement (" Client Agreement" or “Agreement”) is proposed
            by
            TrafficManager
            and sets the terms and conditions you need to follow when using the affiliate
            tracking
            solution ("Platform") or any other Services provided by Us and included in this
            Client
            Agreement, Terms of Conditions, Privacy Policy, and other terms that will be shown
            to
            you from time to time while using our Website, Platform or Services.</p>

         <p class=MsoNormal style='line-height:150%'>This Agreement is accompanied and must be interpreted in conjunction
            with the Privacy
            Policy and the Terms and Conditions located on the Website. In case of any conflict
            between this Client Agreement and the Terms and Conditions or the Privacy Policy,
            this
            Client Agreement shall prevail.</p>

         <p class=MsoNormal style='line-height:150%'>TrafficManager reserves the right to modify the present conditions of
            this Agreement
            at
            any time. The applicable version of this agreement is the one you accepted at the
            time
            of your registration for our Services.</p>

         <p class=MsoNormal style='line-height:150%'>The acceptance of this Agreement by the Client is made by clicking on
            the checkbox “I
            accept the Client Agreement” on the registration page.</p>

         <p class=MsoNormal style='line-height:150%'>If you do not agree with this Agreement do not sign-up to our Website
            and do not
            access
            our Platform or Services. In case you already signed and access our Platform or
            Services, please delete your account immediately.</p>

         <p class=MsoNormal style='line-height:150%'>This contract is concluded for an indefinite period from the date of
            creation of the
            account, and the obligations of the parties take effect from that date.</p>

         <p class=MsoNormal style='line-height:115%'>&nbsp;</p>

         <p class="font-weight-bold">Definitions:</p>

         <ol type="a">
            <li class=MsoNormal style='line-height:115%'>
               TrafficManager: represents the company Traffic Manager Group S.R.L. with registered address in Str. Ionel
               Teodoreanu 1 nr. 1 bl. 1 ap. 1 500165 Brașov, Romania, registration number J08/1846/2020, or other 3rd party
               entities who have the right to provide our clients access to the services via our Website and collect the
               fees
               form the Clients ("we,” "us,” "our");
            </li>

            <li class=MsoNormal style='line-height:115%'>
               Website: represents the website located at the address
               https://www.trafficmanager.com;
            </li>


            <li class=MsoNormal style='line-height:115%'>
               Terms and Conditions: represents the Terms and Conditions with their
               appendices
               and
               all further and subsequent supplements and amendments located at this page:
               https://www.trafficmanager.com/termsofuse
            </li>


            <li class=MsoNormal style='line-height:115%'>
               Privacy Policy: is the document setting out how the data from the User or
               Visitor
               is
               collected, used, retained and transferred. This includes personal data and other
               important information about the consent to Personal Data Processing (
               hereinafter
               the
               “GDPR Regulation”). The document is located at this page:
               https://www.trafficmanager.com/privacy
            </li>


            <li class=MsoNormal style='line-height:115%'>
               Agreement: represents the agreement between TrafficManager (or other
               TrafficManager
               licensee) and the User / Client / Customer which grand the User right to access
               and
               use
               our Services through the Website;
            </li>

            <li class=MsoNormal style='line-height:115%'>
               Services: represent all the services we are providing to the clients via our
               website
               including access to the client dashboard, affiliate tracking platform and all
               the
               upgrades, new features developed, modifications, updates or personal
               customization
               developed in whole or partially by us;
            </li>


            <li class=MsoNormal style='line-height:115%'>
               Platform: it's the TrafficManager affiliate tracking software/technology
               provided
               to
               our clients, including all the updates, new features, customizations, server
               installation and maintenance, updates and monitoring developed in whole or
               partially
               by
               us;
            </li>


            <li class=MsoNormal style='line-height:115%'>
               Visitor: represent any person or business/company visiting our website,
               subject
               to
               our Terms and Conditions and our Privacy Policy but not registered in our
               database;
            </li>


            <li class=MsoNormal style='line-height:115%'>
               User: represent any person or business/company who after accepting our Terms
               and
               Conditions, Privacy Policy and our Client Agreement registered and created a
               user
               account in order to use our Services through the Website ("client” "customer”
               "you”
               "your");
            </li>


            <li class=MsoNormal style='line-height:115%'>
               User Account: the account resulted after a user registration on our Website
               that
               contains personal/company data including payment info with the purpose to use
               our
               Services via the Website;
            </li>


            <li class=MsoNormal style='line-height:115%'>
               Fees: represents the price paid by the Client according to the usage plan
               chosen
               on
               registration and visible in the website pricing page:
               https://www.trafficmanager.com/pricing
            </li>
         </ol>
         <p class=MsoNormal style='line-height:115%'>&nbsp;</p>

         <p class="font-weight-bold">2. Services Description, Pricing, Fees and Payment</p>
         <ol type="a">
            <li class=MsoNormal style='line-height:115%'>
               TrafficManager, offers a SaaS affiliate tracking solution ("Platform") by
               registering
               at the Website www.trafficmanager.com and paying the Fees ("Fees") according to
               the
               usage plan you. You agree that TrafficManager has the right to change the fees
               from
               the
               Pricing page at any time, at its sole discretion and you acknowledge that such
               changes
               will be available on the Website and all the fees are in EURO, unless otherwise
               stated;
            </li>

            <li class=MsoNormal style='line-height:115%'>
               Price: In consideration for the Deliverables, the Client shall pay TrafficManager the fees as set forth on the pricing page or as otherwise agreed upon by the Provider in the event of additional services, such as Custom development, which are tailored to the specific needs of the Client. In the case of custom development requests by clients, the price is set to a minimum of 100 euros for interventions under 1 hour and a maximum of 350 euros per day. A more accurate price will be communicated beforehand through the designated communication channel (ticket or chat), but this price may change if complications arise during the process that were not initially considered or communicated in the initial request. Additionally, if it is necessary to study documentation before providing a price estimate, a cost between 100 and 350 euros will be added to the next invoice. This cost will be incurred even if the feature is not ultimately developed due to the lack of agreement on the price. The price may also vary depending on the chosen payment method and the period for which the Client commits to pay for the Services in advance.<br>
               The chosen method of payment may incur additional fees, as follows:
               <ul>
                  <li class=MsoNormal style='line-height:115%'>Payments made via Stripe will be subject to an additional
                     fee of 2% of the total invoice amount.</li>
                  <li class=MsoNormal style='line-height:115%'>Payments made through PayPal will incur an additional fee of
                     3% of the total invoice amount.</li>
               </ul>
               These fees are meant to cover any potential FX costs that come in addition to the standard processing fees,
               which
               are typically covered by TrafficManager. Clients are encouraged to use fee-free payment methods such as SEPA
               Wire
               Transfer, Visa, or Mastercard to avoid these additional charges.<br>
               By proceeding with the payment for the services, the Client acknowledges and accepts the pricing terms
               provided
               by
               TrafficManager, including any applicable additional fees.<br>
               All stated fees are exclusive of Value Added Tax (VAT), withholding taxes, and/or any other taxes that may
               be
               levied by any jurisdiction. Should VAT, withholding taxes, or any other taxes be imposed on the transactions
               pursuant to this Agreement, the Client is responsible for paying these taxes in such a way that
               TrafficManager
               receives the full amount invoiced to the Client without offset or deduction.
            </li>

            <li class=MsoNormal style='line-height:115%'>
               The duration of the billing period is 1Month / 3 Months / 6 Months or 1 Year on
               prepaid basis and the payment period is set to NET 5 days. In case you decide to
               change
               your subscription plan (Upgrade or Downgrade) you agree that the changes will
               take
               effect starting from the next month billing period but not less than 7 days
               since
               TrafficManager was notified about these changes;
            </li>

            <li class=MsoNormal style='line-height:115%'>
               We offer a 30-day free trial period to all our new clients, but please note that
               the
               decision to provide you with a Trial period or NOT is made at our sole
               discretion;<br>

               For any other info about Billing and Refund Policy please refer to this page:
               https://www.trafficmanager.com/billing<br>

               TrafficManager and its associates attempt to be as accurate as possible.
               However,
               TrafficManager does not warrant that product descriptions or other content of
               this site
               is accurate, complete, reliable, current, or error-free. If a service offered by
               TrafficManager itself is not as described, your sole remedy is to cease using
               it.
            </li>

            <li class=MsoNormal style='line-height:115%'>
               Late Payment Penalties and Recovery Costs: The Client agrees to pay the fees within the stipulated payment
               period,
               set to NET 5 days from the issuance of the invoice. A grace period of an additional 10 days will be granted
               following
               the due date. If the Client fails to make payment within this 15-day period, the Client will be liable to
               pay
               TrafficManager
               a minimum fixed sum of €40 as compensation for recovery costs in line with the European Late Payment
               Directive
               (2011/7/EU).
               This sum is to compensate TrafficManager for the administrative effort and resources expended managing and
               recovering late payments.<br>

               If TrafficManager incurs additional recovery costs above the fixed sum of €40 due to the Client's late
               payment
               (e.g., costs associated with legal action, hiring a collection agency, or additional administrative costs),
               the
               Client agrees to reimburse
               TrafficManager for these additional reasonable expenses.<br>

               The Client acknowledges that these recovery costs are to ensure timely payments, compensate TrafficManager
               for
               damages due to late payment
               and recovery efforts, and not as punitive measures. If late payments persist, TrafficManager reserves the
               right
               to
               suspend or terminate the
               provision of the Services under this Agreement, at its sole discretion.
            </li>
         </ol>
         <p class=MsoNormal style='line-height:115%'>&nbsp;</p>
         <p class="font-weight-bold">3. Grant Of limited Rights</p>

         <p class=MsoNormal style='line-height:150%'>TrafficManager grants you a non-exclusive, revocable, limited and
            non-transferable
            right
            ("Client Agreement") to access our Website and use our Tracking Platform through our
            Website for a fee as indicated above in the point 1 "Platform Description" in
            accordance
            with the terms and conditions of this Client Agreement, The Website TOS, Privacy
            Policy
            and other terms that will be displayed to you from time to time while accessing
            different areas from the Website or Platform for the first time. The Client
            Agreement,
            if not terminated earlier under the Terms of this Agreement is granted for the whole
            period of the Trial or during which the Platform fee is paid for. We reserve our
            right
            to choose the customers of our Platform and the right to refuse access to our
            platform
            to anyone for any reason.</p>

         <p class=MsoNormal style='line-height:150%'>This Client Agreement grants you these limited rights:</p>

         <ol type="a">
            <li class=MsoNormal style='line-height:115%'>
               To use our Platform and all the features you paid for according to the plan
               you
               choose by accessing it via our Website or a White label installed on your own
               web
               domain;
            </li>

            <li class=MsoNormal style='line-height:115%'>
               To gather or upload your data, process, review, analyze, optimize and
               download
               the
               data from the Platform;
            </li>

            <li class=MsoNormal style='line-height:115%'>
               All your data shall be collected and processed in accordance with the GDPR,
               Privacy
               Policy, Terms and Conditions of use and in accordance with the Law. You as the
               platform
               Client and final user of this data you acknowledge and agree that you are
               prohibited
               from using the Platform in violation of any applicable laws and regulations and
               you
               shall be retained fully responsible for any illegal activity you did using this
               data;
            </li>
         </ol>


         <p class="font-weight-bold">4. Platform Account and Agreement Termination</p>

         <ol type="a">
            <li class=MsoNormal style='line-height:115%'>
               You, as the Client agree to assume the whole responsibility for maintaining
               confidential your account login details (user and password) and the whole
               responsibility
               for all the actions that occur under your account or by using your user and
               password. If
               you notice or become aware of any suspect activity on your account or
               unauthorized
               access you agree to notify Us immediately.
            </li>

            <li class=MsoNormal style='line-height:115%'>
               You are considered to start using our Tracking Platform as of the date the
               Free
               Trial
               period has begun (if you received a Free trial period) and you will receive
               access
               to
               the Tracking Platform and to your account until the termination of this
               Agreement,
               subject to monthly payments of the Platform Fees. This Agreement will
               automatically
               renew on a month-to-month basis until you cancel your subscription from your
               account
               settings page, or you provide us with a notice via email or by opening a support
               ticket
               in our system at least 30 days before the cancelation date. On the account
               termination
               you lose your right to access and use the Platform.<br>

               A grace period of 30 days will be granted to the Client after expiration, during
               which
               the user data is not deleted. If the Client pays the overdue pro forma invoice
               during
               the grace period, full access to the client's Platform will be restored.
            </li>

            <li class=MsoNormal style='line-height:115%'>
               For any breach of this Agreement or any Terms and Conditions of use,
               TrafficManager
               may end this agreement without any notice with immediate effect;
            </li>

            <li class=MsoNormal style='line-height:115%'>
               In case of account termination for any reason we shall keep: our rights to
               use
               and
               disclose your feedback about our service and Platform and the right to recover
               from
               you
               any amounts you owe us before the service termination.
            </li>
         </ol>

         <p class="font-weight-bold">5. DISCLAIMER OF WARRANTIES AND LIMITATION</p>

         <p class=MsoNormal style='line-height:150%'>THIS WEBSITE AND PLATFORM ARE PROVIDED BY TRAFFICMANAGER ON AN "AS IS"
            AND "AS
            AVAILABLE" BASIS. TRAFFICMANAGER MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND,
            EXPRESS OR IMPLIED, AS TO THE OPERATION OF THIS SITE OR THE INFORMATION, CONTENT,
            MATERIALS, OR PRODUCTS INCLUDED ON THIS SITE OR PLATFORM. YOU EXPRESSLY AGREE THAT
            YOUR
            USE OF THIS SITE IS AT YOUR SOLE RISK. TO THE FULL EXTENT PERMISSIBLE BY APPLICABLE
            LAW,
            TRAFFICMANAGER DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT
            LIMITED
            TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.</p>

         <p class=MsoNormal style='line-height:150%'>TRAFFICMANAGER DOES NOT WARRANT THAT THIS SITE, ITS SERVERS, E-MAIL
            SENT, PLATFORM OR
            INFORMATION WILL OPERATE UNINTERRUPTED, ERROR-FREE, OR THAT THE SERVERS ARE FREE OF
            VIRUSES, SPYWARE, MALWARE OR OTHER HARMFUL COMPONENTS. FURTHER, TRAFFICMANAGER
            (INCLUDING OUR PROVIDERS AND OTHER OWNER'S LICENSEES) MAKES NO REPRESENTATION OR
            WARRANTY WITH RESPECT TO ANY RESULTS OBTAINABLE THROUGH THE SOFTWARE, THE SITE
            AND/OR
            ASSOCIATED PRODUCTS. NO ADVICE OR INFORMATION, WHETHER VERBAL OR WRITTEN, GIVEN BY
            TRAFFICMANAGER (INCLUDING PROVIDER AND OTHER OWNER'S LICENSEES) THROUGH THE
            PLATFORM,
            THE WEBSITE AND/OR OTHERWISE SHALL CREATE ANY WARRANTY, REPRESENTATION AND/OR
            GUARANTEE
            NOT EXPRESSLY STATED IN HEREIN.</p>

         <p class=MsoNormal style='line-height:150%'>TRAFFICMANAGER WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING
            FROM THE USE OF
            THIS SITE, INCLUDING, BUT NOT LIMITED TO DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, AND
            CONSEQUENTIAL DAMAGES, LOST PROFITS, OR DAMAGES RESULTING FROM LOST DATA OR BUSINESS
            INTERRUPTION RESULTING FROM THE USE OR INABILITY TO USE THE SITE, THE PLATFORM AND
            THE
            CONTENT, WHETHER BASED ON WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY, AND
            WHETHER OR NOT COMPANY IS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>

         <p class=MsoNormal style='line-height:150%'>THE AGGREGATE LIABILITY OF OWNER (INCLUDING PROVIDER AND OTHER OWNER'S
            LICENSEES) TO
            YOU
            FOR ALL CLAIMS ARISING FROM THE SITE, THE PLATFORM AND THE CONTENT IS LIMITED TO 3
            (THREE) MONTHLY FEES FOR THE CHOSEN SUBSCRIPTION PLAN. CERTAIN STATE LAWS DO NOT
            ALLOW
            LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES.
            IF
            THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS, OR
            LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MIGHT HAVE ADDITIONAL RIGHTS.</p>

         <p class="font-weight-bold">6. Tracking Platform and Website USE</p>

         <ol type="a">
            <li class=MsoNormal style='line-height:115%'>
               Beside the responsibilities and obligations you agree with upon registration
               and
               provided in our Terms and Conditions, You as the Platform Client agree that you
               shall
               never try to reverse engineer any part of the TrafficManager Platform,
               reproduce,
               copy,
               trade or sell any part of our Website or Platform and that you will use our
               Website
               and
               our Platform for lawful uses only. Any violation of this section will be
               considered
               as a
               breach of this client agreement.
            </li>

            <li class=MsoNormal style='line-height:115%'>
               You agree that TrafficManager has the right to make any kind of modifications
               or
               updates to the Website and the Tracking Platform at any time in order to provide
               a
               better service to you as a Client (for example: Bug fixes, new features release,
               etc.)
               and in case of such event cause you a service disruption you agree that you will
               notify
               our support team immediately and we shall not be retain responsible for any kind
               of
               data
               or material loss.
            </li>

            <li class=MsoNormal style='line-height:115%'>
               You will have access to our Support service just as provided in our Terms and
               Conditions or in the package you choose from the pricing page:
               https://www.trafficmanager.com/pricing
            </li>

            <li class=MsoNormal style='line-height:115%'>
               You declare that you acknowledge and agree that all the data, material or
               information
               uploaded, gained or retrieved by you and processed with the TrafficManager
               Platform
               on
               your behalf shall not contain any form of "SPAM", is protected by copyright or
               any
               trademark, patent, trade secret or any other intellectual property (without
               authorization).
            </li>

            <li class=MsoNormal style='line-height:115%'>
               You declare that you acknowledge and agree that all the data, material or
               information
               uploaded, gained or retrieved by you and processed with the TrafficManager
               Platform
               on
               your behalf is not false advertising, contains any viruses, worms or any other
               software
               intended to damage or alter a computer system or data, is not defamatory,
               unlawfully
               threatening or harassing, obscene or harmful to minors, abusive, pornographic,
               trade
               libelous, vulgar, racist, discriminating or displaying prejudice based on
               religion,
               promoting hatred, ethnic heritage, race, sexual orientation or age, or (c) that
               violates
               any law or regulation, including without limitation, the laws and regulations
               governing
               export control, unfair competition, anti-discrimination;
            </li>

            <li class=MsoNormal style='line-height:115%'>
               As the Platform Client you authorize TrafficManager to process, transfer and
               store
               the data enabling to identify you and using its own system to check if you are
               using
               the
               TrafficManager tracking platform in accordance with this agreement;
            </li>

            <li class=MsoNormal style='line-height:115%'>
               As the Platform Client you authorize TrafficManager to transfer your data
               during
               any
               kind of communication between our Website/Platform and any other computer
               systems of
               TrafficManager and his partners for the purpose to ensure the full functionality
               of
               the
               Platform and the Authorization to use the Website/Platform and protect the
               TrafficManager rights. You acknowledge and agree also that after the effective
               date
               of
               your registration you accepted this agreement and TrafficManager or any of its
               business
               partners are entitled to process, store and transfer any data in order to
               identify
               you
               for billing purposes or performance of this Agreement;
            </li>
         </ol>

         <p class="font-weight-bold">7. Copyright and Ownership</p>

         <ol type="a">
            <li class=MsoNormal style='line-height:115%'>
               All content included on our Website and Platform, such as text, graphics,
               logos,
               button icons, images, audio clips, digital downloads, data compilations, and
               software,
               is the property of TrafficManager or its content suppliers and protected by
               international copyright laws. The compilation of all content on this site is the
               exclusive property of TrafficManager or its licensors with copyright authorship
               for
               this
               collection by TrafficManager, and protected by international copyright laws. Any
               use,
               copying, redistribution or publication by the Client of any part of the Website
               or
               the
               Platform are strictly prohibited.
            </li>

            <li class=MsoNormal style='line-height:115%'>
               The Client does not acquire the ownership on the Platform, the Website or any
               other
               material made available by TrafficManager through his Website or Platform
               excluding
               all
               the data and information retrieved, uploaded, acquired and processed by the
               TrafficManager Platform on your behalf that shall remain your property and you
               shall
               have the right to copy, duplicate, retrieve, download the data or information
               but
               without disclosing it to third parties. Any other use of information or
               materials
               (except your information) contained in the Platform or Website without the
               written
               permission of TrafficManager or unauthorized use that may violate any applicable
               communications regulations and statutes or laws including but without limitation
               copyright and trademark laws is strictly prohibited and will terminate this
               Agreement.
               This Platform Client agreement can be revoked at any time at the sole discretion
               of
               TrafficManager.
            </li>
         </ol>

         <p class="font-weight-bold">8. Applicable Law and Disputes Resolution</p>

         <ol type="a">
            <li class=MsoNormal style='line-height:115%'>
               This Platform - Client Agreement is governed by the laws of Romania, EU,
               without
               regard to principles of conflict of laws, will govern this Agreement terms and
               any
               dispute of any sort that might arise between you and TrafficManager or its
               associates.
            </li>

            <li class=MsoNormal style='line-height:115%'>
               Any dispute relating in any way to your visit to TrafficManager or to
               products
               you
               purchase through TrafficManager shall be solved in max. 30 (thirty) days by
               negociation
               between the Parties before submitting the dispute to any court in Romania and
               you
               consent to exclusive jurisdiction and venue in such courts except that, to the
               extent
               you have in any manner violated or threatened to violate TrafficManagers
               intellectual
               property rights, TrafficManager may seek injunctive or other appropriate relief
               in
               any
               state or court in Romania, EU;
            </li>
         </ol>

         <p class="font-weight-bold">9. Force Majeure</p>

         <ol type="a">
            <li class=MsoNormal style='line-height:115%'>
               Neither party shall be held liable or responsible to the other party nor be
               deemed to
               have defaulted under or breached this Agreement for failure or delay in
               fulfilling
               or
               performing any obligation under this Agreement when such failure or delay is
               caused
               by
               or results from causes beyond the reasonable control of the affected party,
               including
               but not limited to acts of God, explosions, fires, Internet or network failure,
               telecommunications failure, vandalism, lack of access or breakdown of IT
               systems,
               computer hacking or damage to the data or information maintained in these
               systems, a
               power supply failure or failure in TrafficManagers's systems, administrative or
               legislative interventions, storms or other natural disasters, national
               emergencies,
               riots, insurrections, wars, strikes or other labor difficulties, any act or
               omission
               of
               any other person, entity or any governmental authority; provided, however, that
               the
               party so affected shall use reasonable commercial efforts to avoid or remove
               such
               causes
               of nonperformance, and shall continue performance hereunder with reasonable
               dispatch
               whenever such causes are removed. Either party shall provide the other party
               with
               prompt
               written notice of any delay or failure to perform that occurs by reason of force
               majeure. The parties shall mutually seek a resolution of the delay or the
               failure to
               perform as noted above.
            </li>
         </ol>

         <p class="font-weight-bold">10. MISCELLANEOUS</p>

         <ol type="a">
            <li class=MsoNormal style='line-height:115%'>
               If any provision of this Platform – Client Agreement is found as invalid by
               any
               court
               with competent jurisdiction, the invalidity of this Agreement shall not affect
               the
               validity of the remaining provisions of the Terms and Conditions of this
               Agreement.
               All
               the provisions of this Agreement shall continue and survive termination,
               including
               the
               terms that relates to limitations and exclusions of Trafficmanager's
               restrictions,
               liability, limitations, intellectual property and reimbursement of any damages.
               No
               waiver of any provision of this Agreement shall be deemed a further or
               continuing
               waiver
               of such term or any other term.
            </li>

            <li class=MsoNormal style='line-height:115%'>
               The parties of this Agreement are independent entities, and this agreement
               will
               not
               create any joint venture, partnership, franchise, agency, sales representative
               or
               employment relationship between the Parties, so the Client has no authority to
               accept or
               make any kind of offers or to represent in any way or make statements on behalf
               of
               TrafficManager.
            </li>

            <li class=MsoNormal style='line-height:115%'>
               TrafficManager shall be entitled to assign this Agreement to any 3rd party,
               in
               whole
               or in part without your consent, cases in which this Client – Platform Agreement
               shall
               continue in force, without any changes, with the new entity or person entering
               into
               Trafficmanager's place as the contracting party.
            </li>

            <li class=MsoNormal style='line-height:115%'>
               The Client shall not be entitled to in any way transfer or assign this Client
               Agreement to a 3rd, in whole or in part, without TrafficManagers's prior written
               consent.
            </li>

            <li class=MsoNormal style='line-height:115%'>
               This Client Agreement ("Agreement") together with our Terms and Conditions,
               Privacy
               Policy and other terms that will be displayed to you from time to time when you
               will
               visit for the first-time certain sections of our Website or Platform represent
               the
               entire agreement between you and TrafficManager. In case of any conflict between
               this
               Client Agreement and other Terms and Conditions this Client Agreement shall
               prevail.
            </li>

            <li class=MsoNormal style='line-height:115%'>
               The parties declare that they have negotiated all the terms of this Agreement
               and
               they expressly accepted them by signing the contract, any prior agreement
               between
               the
               parties will not product any legal effects between them.
            </li>

            <li class=MsoNormal style='line-height:115%'>
               TrafficManager reserves the right to periodically update and modify the terms
               of
               this
               Agreement. In such cases, we will inform the Client in advance, by posting the
               modifications on the website 15 days before its entry into force, which is why
               we
               ask
               the Client to periodically check the content of this Agreement.
            </li>

            <li class=MsoNormal style='line-height:115%'>
               Any notification to TrafficManager must be sent electronically to the email
               address
               <a href="mailto:info@trafficmanager.com">info@trafficmanager.com</a>
            </li>
         </ol>

         <p class=MsoNormal style='line-height:150%'>The present Agreement is located in this page:
            https://www.trafficmanager.com/clientagreement</p>
         <p class=MsoNormal style='line-height:115%'>&nbsp;</p>
         <p class=MsoNormal style='line-height:115%'>&nbsp;</p>

         <p class="font-weight-bold text-center">Annex 1 - processing of personal data by
            TrafficManager according to GDPR</p>


         Contract for personal data processing between the Client and TrafficManager

         <p class=MsoNormal style='line-height:150%'>This annex is accompanied and must be interpreted in conjunction with
            the Privacy
            Policy
            and sets out the specific rules regarding the processing of personal data submitted
            by
            the Beneficiary, as Client or Customer, to TrafficManager, as a controller of your
            personal data in accordance with Regulation (EU) 2016/679 on the protection of
            individuals with regard to the processing of personal data and on the free movement
            of
            such data (hereinafter referred to as “GDPR”), as well as any subsequent national
            legislation on the protection of personal data.</p>

         <p class=MsoNormal style='line-height:150%'>By accessing our Website or using our Services, you become subject to
            this Contract.
            Your acceptance of the terms of this Contract, in their entirety, shall be effective
            to
            you and your employees, if any.</p>
         <p class=MsoNormal style='line-height:115%'>&nbsp;</p>

         <p class="font-weight-bold">1. Terms</p>
         <p class=MsoNormal style='line-height:150%'>
            In this annex the terms used will be interpreted in accordance with the GDPR, and
            where
            applicable, the terms used will have the definitions stipulated by art. 4 of the
            GDPR.</p>
         <p class=MsoNormal style='line-height:115%'>&nbsp;</p>

         <p class="font-weight-bold">2. Contract Object</p>
         <p class=MsoNormal style='line-height:150%'>
            The object of personal data processing is the processing by TrafficManager of
            personal
            data sent by the Customer in order to provide the services stipulated in the main
            Customer Agreement but also for customer support services, technical updates or
            updates
            on changes in our usage policies.</p>
         <p class=MsoNormal style='line-height:115%'>&nbsp;</p>

         <p class="font-weight-bold">3. Data Collected</p>
         <p class=MsoNormal style='line-height:150%'>
            Your Contact and Billing Information. When you register or sign up for the
            TrafficManager Services, we may collect certain contact information from you, such
            as
            your first and last name, organization/company name, company number, banking or
            other
            payment information, city, state and zip code, country, email address, and phone
            number
            or other similar contact details to process your registration request, to contact
            you
            regarding it and for finance purposes (invoicing). More details about how we process
            the
            data can be found in the Privacy Policy document located at this page:
            https://www.trafficmanager.com/privacy</p>

         <p class=MsoNormal style='line-height:115%'>&nbsp;</p>
         <p class="font-weight-bold">4. Specific instructions</p>

         <p class=MsoNormal style='line-height:150%'>Under this contract, the Client gives the following specific
            instructions to the
            controller:</p>

         <ul>
            <li class=MsoNormal style='line-height:115%'>
               To collect and process personal data received from the Client directly for the
               purpose
               provided in the article 1 from the Privacy Policy page
               (<a href="https://www.trafficmanager.com/privacy"
                  target="_blank">https://www.trafficmanager.com/privacy</a>)
            </li>

            <li class=MsoNormal style='line-height:115%'>
               To send messages by email on behalf of the Client, for the service of sending
               notifications or documents by email (if this service is used by the Client);
            </li>

            <li class=MsoNormal style='line-height:115%'>
               To collect and process the information sent by the Client to the
               TrafficManager
               Servers via the Website or Platform
            </li>
         </ul>
         <p class=MsoNormal style='line-height:115%'>&nbsp;</p>

         <p class="font-weight-bold">5. Duration of data processing</p>

         <p class=MsoNormal style='line-height:150%'>The duration of the processing of personal data is identical to the
            duration of the
            main
            Client Agreement.</p>
         <p class=MsoNormal style='line-height:115%'>&nbsp;</p>

         <p class="font-weight-bold">6. Nature and purpose of data processing</p>

         <p class=MsoNormal style='line-height:150%'>The nature and purpose of the data processing are those established by
            the Client
            under
            the main Client Agreement, namely the provision of TrafficManager services,
            depending on
            the usage plan or package of services chosen.</p>
         <p class=MsoNormal style='line-height:115%'>&nbsp;</p>

         <p class="font-weight-bold">7. Third Parties</p>

         <p class=MsoNormal style='line-height:150%'>All the Third parties, to whom TrafficManager may transfer the Client
            Data for
            processing are listed under the article 2 from the Privacy Policy page
            (https://www.trafficmanager.com/privacy)</p>

         <ul>
            <li class=MsoNormal style='line-height:115%'>
               Pursuant to this Article, the Client understands to authorize TrafficManager
               to
               process his data thru his third-party partners as listed in the Article 2 from
               the
               Privacy Policy Page.
            </li>

            <li class=MsoNormal style='line-height:115%'>
               For all the future Third Parties that TrafficManager may use to process the
               Client
               data, TrafficManager receives a general authorization to subcontract to any
               other
               provider in the EU, EEA or country with an adequate level of protection
               recognized
               by a
               decision of the European Commission, which is necessary for certain parts of the
               data
               processing under this contract. an adequate level of security, at least at the
               level
               of
               this contract. This authorization includes the obligation to inform the Client,
               through
               a message through the account on the TrafficManager Website or by email. The
               operator
               has the possibility to object within 2 working days.
            </li>
         </ul>
         <p class=MsoNormal style='line-height:115%'>&nbsp;</p>

         <p class="font-weight-bold">8. Rights and Obligations of the Client</p>

         <ul>
            <li class=MsoNormal style='line-height:115%'>
               The right to receive information from TrafficManager or to verify through a
               mandated
               auditor whether TrafficManager has and implements appropriate technical and
               organizational measures so that the processing complies with the requirements of
               the
               GDPR; the verification will take place on the basis of a prior written
               notification,
               sent at least 14 working days before the verification;
            </li>

            <li class=MsoNormal style='line-height:115%'>
               The right to receive assistance from the Controller, in particular to fulfill
               his
               obligation to respond to the requests of data subjects regarding the exercise of
               their
               rights under the GDPR;
            </li>

            <li class=MsoNormal style='line-height:115%'>The right to object to other third parties specified to the
               article 7.2</li>

            <li class=MsoNormal style='line-height:115%'>
               To comply with its obligations under the GDPR as Client with regard to
               personal
               data
               collected or processed by TrafficManager, on its behalf.
            </li>

            <li class=MsoNormal style='line-height:115%'>
               Obligation to inform data subjects under the GDPR, including information on
               the
               processing of data by TrafficManager under this contract
            </li>

            <li class=MsoNormal style='line-height:115%'>
               Obligation to be solely responsible for establishing the legal basis for the
               processing of personal data covered by this contract
            </li>

            <li class=MsoNormal style='line-height:115%'>
               The obligation to implement appropriate technical and organizational measures
               under
               the GDPR, including securing the transfer of data from data subjects to
               TrafficManager;
            </li>

            <li class=MsoNormal style='line-height:115%'>
               The Client understands that from the moment of deleting the data after the end
               of
               the
               provision of services by TrafficManager according to the obligations of GDPR and
               art. 9
               of this contract, the data can no longer be recovered, and it is the entire
               responsibility of the Client to ensure that it has made a complete copy of
               them.
            </li>

            <li class=MsoNormal style='line-height:115%'>
               In all situations where the Client is the one who has to perform an
               obligation,
               such
               as, for example, informing the data subject about the breach of personal data
               security,
               TrafficManager cannot be held liable by the Client's inactions within the scope
               of
               that
               obligation.
            </li>
         </ul>

         <p class=MsoNormal style='line-height:115%'>&nbsp;</p>
         <p class="font-weight-bold">9. Rights and Obligations of TrafficManager</p>

         <ul>
            <li class=MsoNormal style='line-height:115%'>
               The obligation to inform the Customer within a maximum of 10 days, if, in the
               opinion
               of TrafficManager, an instruction violates GDPR and / or other legal provision
               regarding
               the processing of personal data;
            </li>

            <li class=MsoNormal style='line-height:115%'>
               The obligation to ensure the security of personal data processed on behalf of
               the
               Client in accordance with Article 32 of the GDPR and Article 10 of this
               Annex;
            </li>

            <li class=MsoNormal style='line-height:115%'>
               Obligation to inform the Client without undue delay of a breach of the
               security
               of
               the
               Client's personal data during the processing performed by TrafficManager;
            </li>

            <li class=MsoNormal style='line-height:115%'>
               Obligation to assist the Client with all information necessary for the
               notification,
               if any, to the Competent Authority for Data Security Infringement, but without
               substituting for the Client his obligation to notify;
            </li>

            <li class=MsoNormal style='line-height:115%'>
               Obligation to assist the Client in ensuring compliance with the obligations
               set
               out in
               Articles 32-36 of the GDPR;
            </li>

            <li class=MsoNormal style='line-height:115%'>
               The obligation to assist the Client in solving the requests of the data
               subjects
               or to
               send to the Client any request received from the data subjects, in connection
               with
               the
               personal data that have been collected and processed by TrafficManager, within
               maximum 5
               calendar days from its receipt. This assistance does not apply if the Customer
               already
               has in the technical tools provided by TrafficManager the possibility to
               directly
               solve
               the request of the data subject (eg the platform Account access - where the
               Customer
               already has all the information about what data he collects);
            </li>

            <li class=MsoNormal style='line-height:115%'>Obligation not to transmit personal data and / or confidential
               information,
               which
               may
               be personal data, of which he became aware during the performance of the
               contract
            </li>

            <li class=MsoNormal style='line-height:115%'>
               The obligation to provide training to authorized personnel to process personal
               data,
               regarding the confidentiality of such data;
            </li>

            <li class=MsoNormal style='line-height:115%'>
               The obligation to include confidentiality obligations towards employees and
               third
               parties;
            </li>

            <li class=MsoNormal style='line-height:115%'>
               The right to disclose certain personal data by virtue of a legal obligation or
               other
               condition provided by law at the request of an authority, public institution or
               court.
            </li>

            <li class=MsoNormal style='line-height:115%'>The right to recruit third parties according to article 7 of this
               Contract;</li>

            <li class=MsoNormal style='line-height:115%'>
               The right to cover the costs generated by ensuring the assistance of the
               Client,
               by
               the Client, in the situations provided by GDPR according to art. 9, if they
               exceed
               the
               monthly cost of the services provided by TrafficManager;
            </li>

            <li class=MsoNormal style='line-height:115%'>
               The right to use anonymized statistical information as a result of activities
               performed as a result of this contract or its entire activity;
            </li>

            <li class=MsoNormal style='line-height:115%'>
               TrafficManager cannot establish purposes or means of processing personal data,
               these
               being established exclusively by the Client.
            </li>
         </ul>
         <p class=MsoNormal style='line-height:115%'>&nbsp;</p>

         <p class="font-weight-bold">10. Security</p>


         <p class=MsoNormal style='line-height:150%'>TrafficManager must comply with appropriate technical and
            organizational measures to
            ensure appropriate risk-related security measures in line with good industry
            practice.
            In determining the appropriate level of security, TrafficManager must take into
            account
            the current state of development, implementation costs and the nature, scope,
            context
            and purposes of the processing, as well as the risk with varying degrees of
            probability
            and seriousness to the rights and freedoms of individuals. and the risks that arise
            as a
            result of the processing, in particular those that may lead to, accidentally or
            illegally, the destruction, loss, alteration, or unauthorized disclosure of personal
            data transmitted, stored or processed in a otherwise, or to unauthorized access to
            them.</p>

         <p class=MsoNormal style='line-height:150%'>In this context, TrafficManager has established the internal
            application of the
            following organizational and technical security measures for the security of
            personal
            data, taking into account the type of activity performed:</p>

         <ul>
            <li class=MsoNormal style='line-height:115%'>implementation of ISO27001 / ISO27017 / ISO27018 security
               standards</li>

            <li class=MsoNormal style='line-height:115%'>periodic security audit performed by cybersecurity experts</li>

            <li class=MsoNormal style='line-height:115%'>
               limited access to the database for a very small number of employees of
               TrafficManager
            </li>

            <li class=MsoNormal style='line-height:115%'>permanent monitoring of access to the database</li>

            <li class=MsoNormal style='line-height:115%'>
               encryption of the connection used by the Client to access the service using
               SSL
            </li>

            <li class=MsoNormal style='line-height:115%'>encrypted client passwords</li>

            <li class=MsoNormal style='line-height:115%'>regular backups</li>
         </ul>

         <p class=MsoNormal style='line-height:150%'>Voluntarily, TrafficManager may send summaries of the conclusions of
            security
            auditors
            (after deletion of commercial or confidential information) made periodically to
            Customers to demonstrate its ongoing activities on this subject.</p>


         <p class="font-weight-bold">11. Limitation of liability</p>

         <p class=MsoNormal style='line-height:150%'>The Client agrees to exonerate TrafficManager from any liability for
            damages that may
            arise from:</p>

         <ul>
            <li class=MsoNormal style='line-height:115%'>
               non-compliance with the contract due to events that exceed any responsibility
               of
               TrafficManager;
            </li>

            <li class=MsoNormal style='line-height:115%'>
               compliance with the Client's instructions or non-compliance with the Client's
               instructions justified in advance by a notification regarding its
               illegality;
            </li>

            <li class=MsoNormal style='line-height:115%'>
               the lack or vitiation of the consent of the data subjects or of the use of a
               wrong
               legal basis by the Client;
            </li>

            <li class=MsoNormal style='line-height:115%'>non-compliance with the contract due to some actions of the
               Client.</li>
         </ul>


         <p class="font-weight-bold">12. Delimitation of liability</p>

         <p class=MsoNormal style='line-height:150%'>The Customer and TrafficManager define their responsibilities for
            ensuring the
            protection of personal data (e.g. ensuring the confidentiality or security of
            processing), depending on the access and effective control exercised over the data,
            both
            contractually and technically.</p>


         <p class="font-weight-bold">13. Entry into force and amendments</p>

         <p class=MsoNormal style='line-height:150%'>This annex enters into force on May 20, 2018 and is valid until
            modified by
            TrafficManager and informing the Clients accordingly. Using the Account, Platform
            and/or
            our Services after the Clients informing means their consent to this document.</p>

         <p class=MsoNormal style='line-height:115%'>&nbsp;</p>

         <p class=MsoNormal style='line-height:115%'>&nbsp;</p>

         <p class=MsoNormal style='line-height:115%'><b>Client:</b></p>

         <p class=MsoNormal>Name (written out in full): {{ customer.name }}</p>

         <p class=MsoNormal>Company: {{ customer.company }}</p>

         <p class=MsoNormal>Position: {{ customer.title }}</p>

         <p class=MsoNormal>Address: {{ customer.name }}</p>

         <p class=MsoNormal style='line-height:200%'>Other information necessary in
            order for the contract to be binding (if any): </p>

         <p class=MsoNormal>Signature: </p>

         <img v-if="customer.signature != ''" id="signatureImg" class="signature" :src="customer.signature"
            alt="signature">

         <p class=MsoNormal style='line-height:115%'>&nbsp;</p>

         <p class=MsoNormal style='line-height:115%'><b>Parties Service Provider:</b></p>

         <p class=MsoNormal style='line-height:200%'>Name (written out in full):
            Francesco Montanari</p>

         <p class=MsoNormal style='line-height:200%'>Position: CTO &amp; Data Protection
            Officer</p>

         <p class=MsoNormal style='line-height:200%'>Address: Mihail Kogalniceanu 17b,
            bloc C4 , city Brasov, Romania</p>

         <p class=MsoNormal style='line-height:200%'>&nbsp;</p>

         <p class=MsoNormal>Signature: </p>

         <p class=MsoNormal>
            <img src="signature.png" alt="signature" width="300">
         </p>

         <p class=MsoNormal style='line-height:115%'>&nbsp;</p>

         <p class=MsoNormal style='margin-left:288.0pt;line-height:115%'>&nbsp;</p>

         <b><span style='font-size:10.5pt;line-height:115%;font-family:"Calibri",sans-serif'><br clear=all
                  style='page-break-before:always'>
            </span></b>

      </div>

   </div>
</template>

<script>
/* eslint-disable */
export default {
   name: 'ClientAgreement_Template',
   props: {
      customer: {
         type: Object,
         default: null,
      },
   },
   data() {
      return {}
   },
   created() {
      setTimeout(() => this.customer.signature = '', 1000)
   },
}
</script>
   
<style scoped>
/* eslint-disable */
.font-weight-bold {
   font-weight: bold !important;
}

.signature {
   width: 13vw;
   height: auto;
   display: flex;
   margin-left: 3%;
}

.mainSection {
   word-wrap: break-word;
   border-radius: 5px;
   line-height: 1.6;
   letter-spacing: 1.2px;
   word-spacing: 1.5px;
   padding-left: 30px;
   padding-right: 30px;
}

table {
   border-collapse: collapse;
   border: none;
   margin: auto;
   width: 100%;
}

@media screen and (max-width: 460px) {
   .signature {
      width: 23vw;
      height: auto;
      display: flex;
      margin-left: 10%;
   }

   table {
      border-collapse: collapse;
      margin: auto;
      border: none;
      width: auto;
   }

   .mainSection {
      word-wrap: break-word;
      border-radius: 5px;
      line-height: 1.6;
      letter-spacing: 1.2px;
      word-spacing: 1.5px;
      padding-left: 1px;
      padding-right: 1px;
   }
}

p.MsoNormal,
li.MsoNormal,
div.MsoNormal {
   margin-top: 0cm;
   margin-right: 0cm;
   margin-bottom: 8.0pt;
   margin-left: 0cm;
   line-height: 125%;
   font-size: 10.5pt;
   font-family: "Calibri", sans-serif;
   text-align: left;
}

h1 {
   margin-top: 16.0pt;
   margin-right: 0cm;
   margin-bottom: 4.0pt;
   margin-left: 0cm;
   text-align: center;
   page-break-after: avoid;
   font-size: 20.0pt;
   font-family: "Calibri Light", sans-serif;
   color: #2E74B5;
   font-weight: normal;
}

h2 {
   margin-top: 8.0pt;
   margin-right: 0cm;
   margin-bottom: 2.0pt;
   margin-left: 0cm;
   text-align: center;
   page-break-after: avoid;
   font-size: 16.0pt;
   font-family: "Calibri Light", sans-serif;
   font-weight: normal;
}

h3 {
   margin-top: 8.0pt;
   margin-right: 0cm;
   margin-bottom: 0cm;
   margin-left: 0cm;
   page-break-after: avoid;
   font-size: 16.0pt;
   font-family: "Calibri Light", sans-serif;
   font-weight: normal;
}

h4 {
   margin-top: 4.0pt;
   margin-right: 0cm;
   margin-bottom: 0cm;
   margin-left: 0cm;
   line-height: 125%;
   page-break-after: avoid;
   font-size: 15.0pt;
   font-family: "Calibri Light", sans-serif;
   font-weight: normal;
   font-style: italic;
}

h5 {
   margin-top: 2.0pt;
   margin-right: 0cm;
   margin-bottom: 0cm;
   margin-left: 0cm;
   line-height: 125%;
   page-break-after: avoid;
   font-size: 14.0pt;
   font-family: "Calibri Light", sans-serif;
   font-weight: normal;
}

h6 {
   margin-top: 2.0pt;
   margin-right: 0cm;
   margin-bottom: 0cm;
   margin-left: 0cm;
   line-height: 125%;
   page-break-after: avoid;
   font-size: 13.0pt;
   font-family: "Calibri Light", sans-serif;
   font-weight: normal;
   font-style: italic;
}

p.MsoHeading7,
li.MsoHeading7,
div.MsoHeading7 {
   margin-top: 2.0pt;
   margin-right: 0cm;
   margin-bottom: 0cm;
   margin-left: 0cm;
   line-height: 125%;
   page-break-after: avoid;
   font-size: 12.0pt;
   font-family: "Calibri Light", sans-serif;
}

p.MsoHeading8,
li.MsoHeading8,
div.MsoHeading8 {
   margin-top: 2.0pt;
   margin-right: 0cm;
   margin-bottom: 0cm;
   margin-left: 0cm;
   line-height: 125%;
   page-break-after: avoid;
   font-size: 11.0pt;
   font-family: "Calibri Light", sans-serif;
   font-style: italic;
}

p.MsoHeading9,
li.MsoHeading9,
div.MsoHeading9 {
   margin-top: 2.0pt;
   margin-right: 0cm;
   margin-bottom: 0cm;
   margin-left: 0cm;
   line-height: 125%;
   page-break-after: avoid;
   font-size: 10.5pt;
   font-family: "Calibri", sans-serif;
   font-weight: bold;
   font-style: italic;
}

p.MsoHeader,
li.MsoHeader,
div.MsoHeader {
   margin-top: 0cm;
   margin-right: 0cm;
   margin-bottom: 8.0pt;
   margin-left: 0cm;
   line-height: 125%;
   font-size: 10.5pt;
   font-family: "Calibri", sans-serif;
}

p.MsoFooter,
li.MsoFooter,
div.MsoFooter {
   margin-top: 0cm;
   margin-right: 0cm;
   margin-bottom: 8.0pt;
   margin-left: 0cm;
   line-height: 125%;
   font-size: 10.5pt;
   font-family: "Calibri", sans-serif;
}

p.MsoCaption,
li.MsoCaption,
div.MsoCaption {
   margin-top: 0cm;
   margin-right: 0cm;
   margin-bottom: 8.0pt;
   margin-left: 0cm;
   font-size: 8.0pt;
   font-family: "Calibri", sans-serif;
   color: #404040;
   font-weight: bold;
}

p.MsoTitle,
li.MsoTitle,
div.MsoTitle {
   margin-top: 0cm;
   margin-right: 0cm;
   margin-bottom: 20.0pt;
   margin-left: 0cm;
   text-align: center;
   border: none;
   padding: 0cm;
   font-size: 36.0pt;
   font-family: "Calibri Light", sans-serif;
   color: #44546A;
   text-transform: uppercase;
   letter-spacing: 1.5pt;
}

p.MsoTitleCxSpFirst,
li.MsoTitleCxSpFirst,
div.MsoTitleCxSpFirst {
   margin: 0cm;
   text-align: center;
   border: none;
   padding: 0cm;
   font-size: 36.0pt;
   font-family: "Calibri Light", sans-serif;
   color: #44546A;
   text-transform: uppercase;
   letter-spacing: 1.5pt;
}

p.MsoTitleCxSpMiddle,
li.MsoTitleCxSpMiddle,
div.MsoTitleCxSpMiddle {
   margin: 0cm;
   text-align: center;
   border: none;
   padding: 0cm;
   font-size: 36.0pt;
   font-family: "Calibri Light", sans-serif;
   color: #44546A;
   text-transform: uppercase;
   letter-spacing: 1.5pt;
}

p.MsoTitleCxSpLast,
li.MsoTitleCxSpLast,
div.MsoTitleCxSpLast {
   margin-top: 0cm;
   margin-right: 0cm;
   margin-bottom: 20.0pt;
   margin-left: 0cm;
   text-align: center;
   border: none;
   padding: 0cm;
   font-size: 36.0pt;
   font-family: "Calibri Light", sans-serif;
   color: #44546A;
   text-transform: uppercase;
   letter-spacing: 1.5pt;
}

p.MsoSubtitle,
li.MsoSubtitle,
div.MsoSubtitle {
   margin-top: 0cm;
   margin-right: 0cm;
   margin-bottom: 8.0pt;
   margin-left: 0cm;
   text-align: center;
   line-height: 125%;
   font-size: 14.0pt;
   font-family: "Calibri", sans-serif;
   color: #44546A;
}

a:link,
span.MsoHyperlink {
   font-family: "Times New Roman", serif;
   color: #0563C1;
   text-decoration: underline;
}

a:visited,
span.MsoHyperlinkFollowed {
   color: #954F72;
   text-decoration: underline;
}

strong {
   font-family: "Times New Roman", serif;
}

em {
   font-family: "Times New Roman", serif;
   color: black;
}

p.MsoNoSpacing,
li.MsoNoSpacing,
div.MsoNoSpacing {
   margin: 0cm;
   font-size: 10.5pt;
   font-family: "Calibri", sans-serif;
}

p.MsoQuote,
li.MsoQuote,
div.MsoQuote {
   margin-top: 8.0pt;
   margin-right: 36.0pt;
   margin-bottom: 8.0pt;
   margin-left: 36.0pt;
   text-align: center;
   line-height: 125%;
   font-size: 12.0pt;
   font-family: "Calibri", sans-serif;
   color: #7B7B7B;
   font-style: italic;
}

p.MsoIntenseQuote,
li.MsoIntenseQuote,
div.MsoIntenseQuote {
   margin-top: 8.0pt;
   margin-right: 46.8pt;
   margin-bottom: 8.0pt;
   margin-left: 46.8pt;
   text-align: center;
   line-height: 115%;
   font-size: 14.0pt;
   font-family: "Calibri Light", sans-serif;
   color: #2E74B5;
   text-transform: uppercase;
}

span.MsoSubtleEmphasis {
   font-family: "Times New Roman", serif;
   color: #595959;
   font-style: italic;
}

span.MsoIntenseEmphasis {
   font-family: "Times New Roman", serif;
   color: windowtext;
   font-weight: bold;
   font-style: italic;
}

span.MsoSubtleReference {
   font-family: "Times New Roman", serif;
   font-variant: small-caps;
   color: #404040;
   letter-spacing: 0pt;
   text-decoration: underline;
}

span.MsoIntenseReference {
   font-family: "Times New Roman", serif;
   font-variant: small-caps;
   color: windowtext;
   letter-spacing: 0pt;
   font-weight: bold;
   text-decoration: underline;
}

span.MsoBookTitle {
   font-family: "Times New Roman", serif;
   font-variant: small-caps;
   letter-spacing: 0pt;
   font-weight: bold;
}

p.MsoTocHeading,
li.MsoTocHeading,
div.MsoTocHeading {
   margin-top: 16.0pt;
   margin-right: 0cm;
   margin-bottom: 4.0pt;
   margin-left: 0cm;
   text-align: center;
   page-break-after: avoid;
   font-size: 20.0pt;
   font-family: "Calibri Light", sans-serif;
   color: #2E74B5;
}

span.Heading1Char {
   font-family: "Calibri Light", sans-serif;
   color: #2E74B5;
}

span.Heading2Char {
   font-family: "Calibri Light", sans-serif;
}

span.Heading3Char {
   font-family: "Calibri Light", sans-serif;
}

span.Heading4Char {
   font-family: "Calibri Light", sans-serif;
   font-style: italic;
}

span.Heading5Char {
   font-family: "Calibri Light", sans-serif;
}

span.Heading6Char {
   font-family: "Calibri Light", sans-serif;
   font-style: italic;
}

span.Heading7Char {
   font-family: "Calibri Light", sans-serif;
}

span.Heading8Char {
   font-family: "Calibri Light", sans-serif;
   font-style: italic;
}

span.Heading9Char {
   font-family: "Times New Roman", serif;
   font-weight: bold;
   font-style: italic;
}

span.HeaderChar {
   font-family: "Times New Roman", serif;
}

span.FooterChar {
   font-family: "Times New Roman", serif;
}

span.TitleChar {
   font-family: "Calibri Light", sans-serif;
   color: #44546A;
   text-transform: uppercase;
   letter-spacing: 1.5pt;
}

span.SubtitleChar {
   font-family: "Times New Roman", serif;
   color: #44546A;
}

span.QuoteChar {
   font-family: "Times New Roman", serif;
   color: #7B7B7B;
   font-style: italic;
}

span.IntenseQuoteChar {
   font-family: "Calibri Light", sans-serif;
   color: #2E74B5;
   text-transform: uppercase;
}

.MsoChpDefault {
   font-family: "Calibri", sans-serif;
}

ol {
   margin-bottom: 0cm;
}

ul {
   margin-bottom: 0cm;
}
</style>