<template>
  <!-- eslint-disable -->
   <div>
      <div v-if="saveBtn > 0" id="blurLoading"></div>
      <b-row style="display: flex; flex-direction: row-reverse;">
         <b-button id="top-view" class="mr-3" size="sm" v-on:click.prevent="scrollPageTo('bottom-view')">
            <span>BOTTOM</span>
            <i class="bi bi-arrow-bar-down ml-1"></i>
         </b-button>
      </b-row>
      <br><br>
      <DPA_Template :customer="customer" />
      <hr><br><br>
      <b-card-text class="px-5 py-2 mbwindow mobileWidth text-center"
         style="padding-left: 2% !important; padding-right: 2% !important;">
         <h3 class="font-weight-bolder mb-2 text-normal">EXECUTED BY THE PARTIES AUTHORIZED REPRESENTATIVES</h3>
         <div style="margin: auto;">
            <b-col class="py-5 mobileWidth">
               <p style="font-size:18px;" class="mb-2">TrafficManager, by and on behalf of its affiliates, as
                  applicable:</p>
               <h5 class="font-weight-500 text-black">
                  <b class="mr-1">Name:</b> Francesco Montanari
               </h5>
               <h5 class="font-weight-500 text-black">
                  <b class="mr-1">Title:</b> CTO & Data Protection Officer
               </h5><br>
               <h5 class="font-weight-500 text-black">
                  <b class="mr-1">Signature:</b>
                  <img class="signature" src="signature.png" alt="signature">
               </h5>
            </b-col>
            <b-col class="py-2 mobileWidth">
               <p style="font-size:18px;" class="mb-2">
                  Controller, by and on behalf of its affiliates, as applicable:
               </p>
               <validation-observer ref="rules">
                  <validation-provider #default="{ errors }" name="name" rules="required|min:5">
                     <h5 class="mobileWidth font-weight-500 text-black m-auto w-50 h5-form">
                        Name
                        <b-form-input class='placeholderCenter' id="name" ref="name" v-model="customer.name"
                           placeholder="Customer Name" type="text" />
                        <span v-if="dataSaved"><br>{{ customer.name }}</span>
                     </h5>
                     <small class="text-danger">{{ errors[0] }}</small><br>
                  </validation-provider>
                  <validation-provider #default="{ errors }" name="company" rules="required|min:3">
                     <h5 class="mobileWidth font-weight-500 text-black w-50 m-auto h5-form">
                        Company
                        <b-form-input class='placeholderCenter' id="company" ref="company" v-model="customer.company"
                           placeholder="Customer Company" type="text" />
                        <span v-if="dataSaved"><br>{{ customer.company }}</span>
                     </h5>
                     <small class="text-danger">{{ errors[0] }}</small><br>
                  </validation-provider>
                  <validation-provider #default="{ errors }" name="title" rules="required|min:3">
                     <h5 class="mobileWidth font-weight-500 text-black w-50 m-auto h5-form">
                        Company Position
                        <b-form-input class='placeholderCenter' id="title" ref="title" v-model="customer.title"
                           placeholder="EX: CEO. of the COMPANY" type="text" />
                        <span v-if="dataSaved"><br>{{ customer.title }}</span>
                     </h5>
                     <small class="text-danger">{{ errors[0] }}</small><br>
                  </validation-provider>
                  <validation-provider #default="{ errors }" name="address" rules="required|min:10">
                     <h5 class="mobileWidth font-weight-500 text-black w-50 m-auto h5-form">
                        Company Address
                        <b-form-input class='placeholderCenter' id="address" ref="address" v-model="customer.address"
                           placeholder="Company Address" type="text" />
                        <span v-if="dataSaved"><br>{{ customer.address }}</span>
                     </h5>
                     <small class="text-danger">{{ errors[0] }}</small><br>
                  </validation-provider>
               </validation-observer>
               <!-- Section: Signature -->
               <h5 class="font-weight-500 text-black">
                  <b>Signature</b><br><b-button v-if="!dataSaved" style="margin-bottom: 3.5px; margin-top: 2px;"
                     @click="clearSignature">RETRY Signature</b-button>
                  <div class="containerr">
                     <canvas class="canvas" width="500" height="300"
                        style="border:1px solid lightgray; background-color:#fafafa; border-radius: 10px;" ref="canvas"
                        @mousedown="startSignature" @mouseup="stopSignature" @mousemove="drawSignature"></canvas><br>
                     <small v-if="validator.some(e => e == 'sign')" class="text-danger font-weight-bolder"
                        style="font-size:15px;">Empty signature! <br></small>
                     <span id="informative-text">(please write your signature with the mouse or use your finger if you
                        are on the phone)</span>
                  </div>
                  <div id="signatureImg"></div>
               </h5><br>
               <!-- Button: Save -->
               <b-button v-if="!dataSaved && saveBtn > -1" variant="warning" @click="saveData">
                  <span v-if="saveBtn > 0">
                     <b-spinner small type="grow" />
                     Saving...
                  </span>
                  <span v-else-if="saveBtn == 0">Save Details</span>
               </b-button>
               <!-- Button: Download -->
               <div v-if="generatedDPA" class="align-items-center">
                  <b-button variant="success" :disabled="downloading" @click="downloadPdf">
                     <span v-if="downloading">
                        <b-spinner small type="grow" />
                        Downloading...
                     </span>
                     <span v-else>Download</span>
                  </b-button>
               </div>
               <b-row style="display: flex; flex-direction: row-reverse; margin-top: 10px;">
                  <b-button id="bottom-view" size="sm" v-on:click.prevent="scrollPageTo('top-view')">
                     <span>TOP</span>
                     <i class="bi bi-arrow-bar-up ml-1"></i>
                  </b-button>
               </b-row>
               <div v-if="saveBtn > 0" id="blurLoading2">
                  <div class="spinner-border" id='loadingDownload' role="status"></div><br>
                  <span>
                     We are preparing the PDF in the background<br>You will receive a notification when it is
                     ready.<br><br>This window will close in {{ saveBtn }} seconds.
                  </span>
               </div>
            </b-col>
         </div>
      </b-card-text>
   </div>
</template>

<script>
/* eslint-disable */
import {
   BRow, BCol, BCard, BCardText, BFormSelect, BBadge, BCardHeader,
   BFormGroup, BFormInput, BFormRadioGroup, BCardBody, BInputGroupText,
   BInputGroup, BSpinner, BButton, BModal, BAlert,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full.min'
import axios from '@/libs/axios'
import DPA_Template from "./DPA_Template.vue"

export default {
   name: 'DpaAgreementVue',
   components: {
      BRow,
      BCol,
      BCard,
      BCardText,
      BFormSelect,
      BFormGroup,
      BFormInput,
      BFormRadioGroup,
      BCardBody,
      BBadge,
      BCardHeader,
      BInputGroup,
      BInputGroupText,
      BSpinner,
      BButton,
      ValidationProvider,
      ValidationObserver,
      BModal,
      BAlert,
      DPA_Template
   },
   props: {
      customer: {
         type: Object,
         default: null,
      },
   },
   emits: ['generatedPDF', 'userAgreeToDPA'],
   data() {
      return {
         generatedDPA: false,
         validator: [],
         signature: null,
         isDrawing: false,
         signatureImage: -1,
         userData: null,
         downloading: false,
         dataSaved: false,
         pdfTemplate: null,
         saveBtn: 0,
         signaturePad: null,
      }
   },
   computed: {
      isPhone: {
         get() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) return true;
            return false;
         }
      },
   },
   mounted() {
      const canvas = document.getElementsByClassName("canvas")[0];

      // stop scrolling
      canvas.addEventListener("touchstart", this.startSignature, false);
      canvas.addEventListener("touchend", this.stopSignature, false);
      canvas.addEventListener("touchmove", this.drawSignature, false);

      setTimeout(() => {
         window.addEventListener("resize", this.resizeCanvas);
         this.resizeCanvas();
         if (this.isPhone) {
            this.drawLine(canvas.getContext('2d'), [canvas.width * 0.035, 150], [canvas.width * 0.3, 150], 'black', 1);
         } else {
            this.drawLine(canvas.getContext('2d'), [canvas.width * 0.08, canvas.height * 0.6], [canvas.width * 0.72, canvas.height * 0.6], 'black', 1);
         }
      }, 1200);
   },
   methods: {
      resizeCanvas() {
         const ratio = Math.max(window.devicePixelRatio || 1, 1);
         const canvas = document.getElementsByClassName("canvas")[0];

         canvas.width = canvas.offsetWidth * ratio;
         canvas.height = canvas.offsetHeight * ratio;
         canvas.getContext("2d").scale(ratio, ratio);

         if (this.isPhone) {
            this.drawLine(canvas.getContext('2d'), [canvas.width * 0.035, 150], [canvas.width * 0.3, 150], 'black', 1);
         } else {
            this.drawLine(canvas.getContext('2d'), [canvas.width * 0.08, canvas.height * 0.6], [canvas.width * 0.72, canvas.height * 0.6], 'black', 1);
         }
      },

      async downloadPdf() {
         this.downloading = true;
         const payload = {
            name: this.customer.name,
            company: this.customer.company,
            date: this.customer.dateSigned,
         };
         await axios.post(`/api/dpa/download`, payload, { responseType: 'blob' }).then(res => {
            if (!res || !res?.data) {
               this.notify({
                  text: 'The PDF has not yet been generated by the server, please try again later !',
                  variant: 'danger',
               });
               this.downloading = false;
               return;
            }
            const link = document.createElement('a');
            const pdf_url = URL.createObjectURL(res.data);
            link.setAttribute('href', pdf_url);
            link.setAttribute('download', `${this.customer.name.replaceAll(" ", "_")}_DPA_agreement.pdf`);
            link.style.visibility = 'hidden';
            link.id = 'downloadPDF';
            document.body.appendChild(link);
            document.getElementById(link.id).click();

            const element = document.getElementById(link.id);
            element.remove();
         });
         this.downloading = false;
      },

      async saveData() {
         this.saveBtn = 5;
         this.validator = [];

         const name = document.getElementById("name");
         const company = document.getElementById("company");
         const title = document.getElementById("title");
         const address = document.getElementById("address");
         const canvas = document.getElementsByClassName("canvas")[0];

         if (name.value == "" || name.value.length < 5) this.validator.push(name.value);
         if (company.value == " " || company.value.length < 3) this.validator.push(company.value);
         if (title.value == " " || title.value.length < 3) this.validator.push(title.value);
         if (address.value == " " || address.value.length < 10) this.validator.push(title.address);
         if (this.isCanvasBlank(canvas)) this.validator.push('sign');

         if (this.validator.length > 0) {
            this.notify({
               text: 'Please make sure you sign the DPA and fill out the form completely. Thank you!',
               variant: 'danger',
            });
            this.saveBtn = 0;
            return;
         }
         setTimeout(() => {
            const disableScroll = function (e) { e.preventDefault(); }
            const divP = document.getElementById('blurLoading').parentElement;
            divP.addEventListener("scroll", disableScroll);
            document.body.style.overflowY = 'hidden';
         }, 1000);

         name.remove();
         company.remove();
         title.remove();
         address.remove();
         canvas.remove();
         document.getElementById("informative-text").remove();

         const image = new Image();
         this.drawLine(this.$refs.canvas.getContext('2d'),
            [this.$refs.canvas.width * 0.08, this.$refs.canvas.height * 0.6],
            [this.$refs.canvas.width * 0.72, this.$refs.canvas.height * 0.6],
            'black', 1
         );
         const divSignature = document.getElementById('signatureImg');
         image.src = this.trimCanvas(this.$refs.canvas).toDataURL();
         image.style.width = '20vw';
         image.style.height = 'auto';
         divSignature.appendChild(image);

         this.customer.signature = this.trimCanvas(this.$refs.canvas).toDataURL();
         if (this.isPhone) image.style.width = '40vw';

         const payload = {
            uid: this.customer.uid,
            full_name: name.value,
            title: title.value,
            company: company.value,
            address: address.value,
            signature: image.src,
         }
         const closeBlur = setInterval(() => {
            if (this.saveBtn > -1) this.saveBtn--;
            if (this.saveBtn == 0) {
               clearInterval(closeBlur);
               this.dataSaved = true;
               this.saveBtn = -1;
            }
            if (this.saveBtn == 2) this.generatePDF(payload);
         }, 1000);
         await axios.post("/api/dpa/store", payload);
         this.$emit('userAgreeToDPA', true);
      },

      generatePDF(payload) {
         axios.post("/api/dpa/generate_pdf", payload).then((res) => {
            if (res?.status === 200) {
               const msg = `The PDF has been generated, now you can download it.`;
               const h = this.$createElement;
               const $closeButton = h('button', {
                  type: "button",
                  class: [
                     'd-flex',
                     'justify-content-center',
                     'btn btn-outline-success',
                     'btn-sm',
                     'w-50',
                     'text-center',
                     'mx-auto'
                  ],
                  style: { "margin-top": '5px' },
                  on: { click: () => this.downloadPdf() }
               }, ['Download']);
               this.$bvToast.toast([msg, $closeButton], {
                  title: 'PDF Generated Successfully',
                  variant: 'success',
                  solid: true,
                  autoHideDelay: 10000,
               });
               this.generatedDPA = true;
            }
            this.$emit('generatedPDF', this.generatedDPA);
         });
      },

      startSignature(e) {
         this.isDrawing = true;
         this.signature = this.$refs.canvas.getContext('2d');
         this.signature.strokeStyle = "darkblue";
         if (this.isPhone) this.signature.lineWidth = 1.45;
         else this.signature.lineWidth = 1.5;
         this.signature.fillStyle = 'lightgray';
         this.signature.moveTo(e.offsetX, e.offsetY);
      },

      stopSignature(e) {
         e.preventDefault();
         this.isDrawing = false;
         this.signature.beginPath();
      },

      drawLine(ctx, begin, end, stroke = 'black', width) {
         if (stroke) ctx.strokeStyle = stroke;
         if (width) ctx.lineWidth = width;

         ctx.beginPath();
         ctx.moveTo(...begin);
         ctx.lineTo(...end);
         ctx.stroke();
         ctx.beginPath();
      },

      drawSignature(e) {
         if (!this.isDrawing) return;
         e.preventDefault();

         if (e.type == 'touchmove') {
            const rect = this.$refs.canvas.getBoundingClientRect();
            this.signature.lineTo(e.touches[0].clientX - rect.left, e.touches[0].clientY - rect.top);
         } else if (e.type == 'mousemove') {
            this.signature.lineTo(e.offsetX, e.offsetY);
         }

         this.signature.stroke();
      },

      clearSignature() {
         this.$refs.canvas.width = this.$refs.canvas.width;
         this.resizeCanvas();
      },

      // returns true if every pixel's uint32 representation is 0 (or "blank")
      isCanvasBlank(canvas) {
         const context = canvas.getContext('2d');
         const pixelBuffer = new Uint32Array(context.getImageData(0, 0, canvas.width, canvas.height).data.buffer);

         return !pixelBuffer.some(color => color !== 0);
      },

      trimCanvas(canvas) {
         const content = canvas;
         const context = content.getContext('2d');

         const topLeft = {
            x: content.width,
            y: content.height,
            update(x, y) {
               this.x = Math.min(this.x, x);
               this.y = Math.min(this.y, y);
            }
         };

         const bottomRight = {
            x: 0,
            y: 0,
            update(x, y) {
               this.x = Math.max(this.x, x);
               this.y = Math.max(this.y, y);
            }
         };
         const imageData = context.getImageData(0, 0, content.width, content.height);

         for (let x = 0; x < content.width; x++) {
            for (let y = 0; y < content.height; y++) {
               const alpha = imageData.data[((y * (content.width * 4)) + (x * 4)) + 3];
               if (alpha !== 0) {
                  topLeft.update(x, y);
                  bottomRight.update(x, y);
               }
            }
         }
         const width = bottomRight.x - topLeft.x;
         const height = bottomRight.y - topLeft.y;

         const croppedCanvas = context.getImageData(topLeft.x, topLeft.y, width, height);
         content.width = width;
         content.height = height;
         context.putImageData(croppedCanvas, 0, 0);

         return content;
      },

      scrollPageTo(navEl) {
         let element = document.querySelector(`#${navEl}`);
         element.scrollIntoView({ behavior: "smooth" });
      }
   },
}
</script>

<style scoped>
.placeholderCenter {
   text-align: center;
}

#blurLoading {
   height: 100%;
   width: 100%;
   position: absolute;
   background-color: #fafafae3;
   top: 0;
   left: 0;
   z-index: 999;
   filter: blur(10px);
}

#blurLoading2 {
   position: fixed;
   z-index: 1000;
   bottom: 45%;
   margin-left: 30%;
   transform: scale(1.7);
   font-weight: bold;
   color: black;
}

.canvas {
   width: 500px;
   height: 300px;
}

#signatureImg {
   margin: auto;
}

.signature {
   width: 30vw;
   height: auto;
}

input {
   min-width: 250px;
   max-width: 80%;
}

iframe {
   width: 100%;
   height: 1000px;
   overflow-x: hidden;
}

.h5-form {
   display: flex;
   flex-direction: column;
   align-items: center;
}

@media screen and (max-width: 960px) {
   .canvas {
      width: 100%;
      height: auto;
   }

   #blurLoading2 {
      position: absolute;
      z-index: 1000;
      bottom: 35%;
      margin-left: 2vw;
      font-weight: bold;
      transform: scale(1);
   }
}

@media screen and (max-width: 600px) {
   .canvas {
      width: 350px;
      height: 200px;
   }

   .signature {
      width: 100%;
      height: auto;
   }

   #blurLoading2 {
      position: absolute;
      z-index: 1000;
      bottom: 35%;
      margin-left: 2vw;
      font-weight: bold;
      transform: scale(1);
   }
}

/* iphone 12/13 pro max - android pro max*/
@media screen and (max-width: 428px) {
   .canvas {
      width: 100%;
      height: 200px;
   }

   .signature {
      width: 100%;
      height: auto;
   }

   #blurLoading2 {
      position: absolute;
      z-index: 1000;
      bottom: 35%;
      margin-left: 2vw;
      font-weight: bold;
      transform: scale(1);
   }
}

/* iphone 12/13 pro - android pro*/
@media screen and (max-width: 395px) {
   .canvas {
      width: 100%;
      height: 200px;
   }

   .signature {
      width: 100%;
      height: auto;
   }

   #blurLoading2 {
      position: absolute;
      z-index: 1000;
      bottom: 35%;
      margin-left: 2vw;
      font-weight: bold;
      transform: scale(1);
   }
}

/* smaller phone */
@media screen and (max-width: 365px) {
   .canvas {
      width: 100%;
      height: 200px;
   }

   .signature {
      width: 100%;
      height: auto;
   }

   #blurLoading2 {
      position: absolute;
      z-index: 1000;
      bottom: 35%;
      margin-left: 2vw;
      font-weight: bold;
      transform: scale(1);
   }
}

@media screen and (max-width: 330px) {
   .canvas {
      width: 100%;
      height: 200px;
   }

   .signature {
      width: 100%;
      height: auto;
   }

   #blurLoading2 {
      position: absolute;
      z-index: 1000;
      bottom: 35%;
      margin-left: 2vw;
      font-weight: bold;
      transform: scale(1);
   }
}
</style>
